// GlobalStyles.js
import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  html, body {
      overscroll-behavior: auto;
    }
  body {
    font-family: ${(props) => props.theme.font.family};
    font-size: ${(props) => props.theme.font.size}px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: ${(props) => props.theme.colors.text};
    background-image: linear-gradient(${(props) =>
        props.theme.colors.secondary}, ${(props) =>
    props.theme.colors.background} 45%);
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${(props) => props.theme.font.heading.family};
    font-weight: ${(props) => props.theme.font.heading.weight};
    margin-block: ${(props) => props.theme.spacing.margin.small}px;
  }

  a {
    color: ${(props) => props.theme.link.primary.color};
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: ${(props) => props.theme.link.primary.hover.color};
    }

    &:disabled {
      color: ${(props) => props.theme.link.primary.disabled.color};
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    margin-inline: 10px;
    min-height: 100dvh;
    max-width: ${(props) => props.theme.layout.maxWidth}px;
    margin: 0 auto;
    padding: 0 ${(props) => props.theme.spacing.padding.large}px;
  }

  .page-content {
    margin-block: 80px;

  }

  .form-group {
    display: flex;
    flex-direction: column;
  }

  label {
    margin-block: ${(props) => props.theme.spacing.margin.small}px;
  }

  .form-error {
    margin-block-end: ${(props) => props.theme.spacing.margin.small}px;
    color: ${(props) => props.theme.colors.error};
    font-size: small;
  }

  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}
`;
