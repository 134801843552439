import { createSlice } from "@reduxjs/toolkit";
import { login } from "./loginAction";
import { LOGIN_STATE_INTERFACE } from "../interfaces/login";

const initialState: LOGIN_STATE_INTERFACE = {
    loginIsLoading: false,
    loginError: null,
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(login.pending, (state) => {
                state.loginIsLoading = true;
            })
          .addCase(login.fulfilled, (state, action) => {
                state.loginIsLoading = false;
                state.loginError = null;
            })
          .addCase(login.rejected, (state, action) => {
                state.loginIsLoading = false;
                state.loginError = action.payload;
            });
    },
})

export default loginSlice.reducer;