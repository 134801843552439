import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const endPoint = process.env.REACT_APP_API_URL;

export const createDeposit = createAsyncThunk(
    "deposit/createDeposit",
    async ({ user }: { user?: number | string }, thunkApi) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const res = await axios.post(
                `${endPoint}/deposits/`,
                { user },
                config
            );
            return thunkApi.fulfillWithValue(res);
        } catch (error: any) {
            return thunkApi.rejectWithValue(await error?.response?.data);
        }
    }
);

export const updateDeposit = createAsyncThunk(
    "deposit/updateDeposit",
    async (
        { user, id }: { user?: number | string; id?: number | string },
        thunkApi
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const res = await axios.put(
                `${endPoint}/deposits/${id}/`,
                { user },
                config
            );
            return thunkApi.fulfillWithValue(res);
        } catch (error: any) {
            return thunkApi.rejectWithValue(await error?.response?.data);
        }
    }
);

export const getDeposit = createAsyncThunk(
    "deposit/getDeposit",
    async ({ id }: { id?: string }, thunkApi) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };

            const res = await axios.get(`${endPoint}/deposits/${id}/`, config);
            return thunkApi.fulfillWithValue(res);
        } catch (error: any) {
            return thunkApi.rejectWithValue(await error.response.data);
        }
    }
);
