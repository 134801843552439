import styled from "styled-components";

export const TopNavContainer = styled.div<{
    isHome: boolean;
    colorChange: boolean;
    hasUnreadNotification: boolean;
}>`
    position: fixed;
    top: 0;
    width: calc(100dvw - 48px);
    padding: 20px 24px;
    margin-inline: -24px;
    display: flex;
    flex: 1;
    justify-content: space-around;
    align-items: center;
    z-index: 1;
    background-color: ${({ theme, isHome, colorChange }) =>
        isHome
            ? colorChange && theme.colors.secondary
            : theme.colors.background};

    @media only screen and (min-width: 501px) {
        width: 500px;
        left: auto;
        right: auto;
    }

    & > div > svg,
    & > div > div > svg {
        color: ${({ theme }) => theme.colors.primary};
    }

    & > div > .fa-dot-circle {
        color: ${({ theme, hasUnreadNotification }) =>
            hasUnreadNotification ? theme.colors.error : theme.colors.primary};
    }
`;

export const TopNavPageName = styled.div`
    margin-inline: auto;
    font-weight: bolder;
`;
