import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TopNavContainer, TopNavPageName } from "./TopNav.styled";
import { FaCircleUser } from "react-icons/fa6";
import { IoMdNotifications, IoMdArrowRoundBack } from "react-icons/io";
import { FaDotCircle } from "react-icons/fa";
import { RiCheckDoubleLine } from "react-icons/ri";
import { useNotifications } from "../../pages/notifications/hooks/useNotifications";
import { RootState, store } from "../../app/store";
import { notificationMarkAllAsRead } from "../../pages/notifications/redux/notificationsAction";
import { useSelector } from "react-redux";
import { calculateUserLevelAndReward } from "../../utils/levelsAndRewards";
import { useUserDownlines } from "../User/hooks/useUser";

const TopNav = () => {
    const navigate = useNavigate();
    const { hasUnreadNotification } = useNotifications();
    const { user } = useSelector((state: RootState) => state.user);
    const { userDownlines, earning } = useUserDownlines();

    const numberOfUserDownlines = userDownlines.filter(
        (invite) => invite.has_machine
    ).length;

    const { userLevel } = calculateUserLevelAndReward(
        numberOfUserDownlines,
        earning
    );

    const [colorChange, setColorchange] = useState(false);
    const changeNavbarColor = () => {
        if (window.scrollY >= 80) {
            setColorchange(true);
        } else {
            setColorchange(false);
        }
    };
    window.addEventListener("scroll", changeNavbarColor);

    return (
        <TopNavContainer
            isHome={window.location.pathname === "/"}
            colorChange={colorChange}
            hasUnreadNotification={hasUnreadNotification}
        >
            {window.location.pathname === "/" ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                    }}
                >
                    <FaCircleUser
                        size={40}
                        onClick={() => navigate("profile")}
                        style={{ cursor: "pointer" }}
                    />
                    <div style={{ width: "60%" }}>
                        {user.full_name && (
                            <div
                                style={{
                                    display: "flex",
                                }}
                            >
                                <h2
                                    style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        lineHeight: 1,
                                        marginBlock: 0,
                                    }}
                                >
                                    {user.full_name}
                                </h2>
                            </div>
                        )}
                        <div style={{ color: "#00b894" }}>
                            Level {userLevel - 1}
                        </div>
                    </div>
                </div>
            ) : (
                <IoMdArrowRoundBack
                    size={30}
                    onClick={() => navigate(-1)}
                    style={{ cursor: "pointer" }}
                />
            )}
            <TopNavPageName>
                {window.location.pathname !== "/" &&
                    window.location.pathname
                        .slice(1)
                        .replace("-", " ")
                        .split("/")[0]
                        .toUpperCase()}
            </TopNavPageName>
            {window.location.pathname === "/" && (
                <div
                    style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                    onClick={() => navigate("/notifications")}
                >
                    <FaDotCircle
                    className="fa-dot-circle"
                        size={10}
                        style={{
                            position: "absolute",
                            top: 0,
                            right: -2,
                        }}
                    />
                    <div>
                        <IoMdNotifications size={30} />
                    </div>
                </div>
            )}
            {window.location.pathname === "/notifications" && (
                <div
                    style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        store.dispatch(
                            notificationMarkAllAsRead({ user_id: user.id })
                        );
                    }}
                >
                    <RiCheckDoubleLine size={30} />
                </div>
            )}
        </TopNavContainer>
    );
};

export default TopNav;
