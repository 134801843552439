import { createSlice } from "@reduxjs/toolkit";
import { changePassword } from "./changePasswordActions";

export interface CHANGE_PASSWORD_ACTIONS {
    changePasswordIsLoading: boolean;
    changePasswordError?: string | null | unknown;
}

const initialState: CHANGE_PASSWORD_ACTIONS = {
    changePasswordIsLoading: false,
    changePasswordError: null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(changePassword.pending, (state) => {
                state.changePasswordIsLoading = true;
                state.changePasswordError = null;
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                state.changePasswordIsLoading = false;
                state.changePasswordError = null;
            })
            .addCase(changePassword.rejected, (state, action) => {
                state.changePasswordIsLoading = false;
                state.changePasswordError = action.payload;
            });
    },
});

export default authSlice.reducer;
