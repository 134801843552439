import React from "react";
import { useNotifications } from "./hooks/useNotifications";
import NotificationList from "../../components/NotificationList/NotificationList";
import PageLoading from "../../components/PageLoading/PageLoading";
import EmptyList from "../../components/EmptyList/EmptyList";

const Notifications = () => {
    const {
        userNotifications,
        getUserNotificationsLoading,
        notificationMarkAllAsReadLoading,
    } = useNotifications();

    if (getUserNotificationsLoading || notificationMarkAllAsReadLoading) {
        return <PageLoading transparentBackground={true} />;
    }

    if (!userNotifications.length) {
        return <EmptyList message={"No Notification"} />;
    }

    return (
        <div className="page-content">
            <NotificationList userNotifications={userNotifications} />
        </div>
    );
};

export default Notifications;
