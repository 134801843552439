import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { login } from "../redux/loginAction";
import { toast } from "react-toastify";
import { RootState, store } from "../../../app/store";
import { LOGIN_INTERFACE } from "../interfaces/login";
import { toastOption } from "../../../constants";

export const useLogin = () => {
    const minUserChar = 3;
    const navigate = useNavigate();

    const { loginIsLoading, loginError } = useSelector(
        (state: RootState) => state.login
    );

    const initialValues: LOGIN_INTERFACE = {
        username: "",
        password: "",
    };

    const onSubmit = (values: LOGIN_INTERFACE) => {
        store
            .dispatch(login(values))
            .unwrap()
            .then((res) => {
                toast.success("Login successful", toastOption);
                navigate("/");
            })
            .catch((error) => {
                console.log(error);
                toast.error(`${error?.non_field_errors[0]}`, toastOption);
            });
    };

    const validationSchema = Yup.object({
        username: Yup.string()
            .min(minUserChar, `Username must be ${minUserChar}`)
            .required("This field cannot be empty")
            .matches(/^\S*$/, "Username cannot contain spaces"),
        password: Yup.string().required("This field cannot be empty"),
    });

    const formik = useFormik<LOGIN_INTERFACE>({
        initialValues,
        onSubmit,
        validationSchema,
    });

    return {
        formik,
        loginIsLoading,
        loginError,
        navigate,
    };
};
