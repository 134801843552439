import { createSlice } from "@reduxjs/toolkit";
import { createDeposit, getDeposit } from "./DepositActions";

export interface DEPOSIT_OBJECT {
    status?: string;
    user?: string;
    amount?: number;
    address_used?: string;
    wallet?: string;
    date: string | number | Date;
}

export interface DEPOSIT_INITIAL_STATE {
    createDepositIsLoading: boolean;
    deposit: DEPOSIT_OBJECT;
    createDepositError?: any;
    getDepositIsLoading: boolean;
    getDepositError?: any;
}

const initialState: DEPOSIT_INITIAL_STATE = {
    createDepositIsLoading: false,
    createDepositError: null,
    deposit: {
        date: new Date(),
    },
    getDepositIsLoading: false,
    getDepositError: null,
};

const DepositSlice = createSlice({
    name: "DepositSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createDeposit.pending, (state) => {
                state.createDepositIsLoading = true;
            })
            .addCase(createDeposit.fulfilled, (state, action) => {
                state.createDepositIsLoading = false;
                state.deposit = action.payload.data;
            })
            .addCase(createDeposit.rejected, (state, action) => {
                state.createDepositIsLoading = false;
                state.createDepositError = action.payload;
            })
            .addCase(getDeposit.pending, (state) => {
                state.getDepositIsLoading = true;
            })
            .addCase(getDeposit.fulfilled, (state, action) => {
                state.getDepositIsLoading = false;
                state.deposit = action.payload.data;
            })
            .addCase(getDeposit.rejected, (state, action) => {
                state.getDepositIsLoading = false;
                state.getDepositError = action.payload;
            });
    },
});

export default DepositSlice.reducer;
