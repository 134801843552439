import { EarningInterface } from "../components/User/redux/interface";

function calculatePercentage(
    downlines: number,
    currentThreshold: number,
    nextThreshold: number
) {
    return (
        ((downlines - currentThreshold) / (nextThreshold - currentThreshold)) *
        100
    );
}

export function calculateUserLevelAndReward(
    numberOfUserDownlines: number,
    earning: EarningInterface
) {
    let userLevel;
    let friends_to_inv;
    let reward;
    let claimed;
    let can_claim;
    let percentage;
    const LEVEL_1_THRESHOLD = 1;
    const LEVEL_2_THRESHOLD = 3;
    const LEVEL_3_THRESHOLD = 8;
    const LEVEL_4_THRESHOLD = 20;
    const LEVEL_5_THRESHOLD = 50;
    const LEVEL_6_THRESHOLD = 100;
    const LEVEL_7_THRESHOLD = 200;

    switch (true) {
        case numberOfUserDownlines <= LEVEL_1_THRESHOLD:
            userLevel = 1;
            reward = 100;
            friends_to_inv = LEVEL_1_THRESHOLD;
            can_claim = numberOfUserDownlines >= LEVEL_1_THRESHOLD;
            claimed =
                earning?.[`claimed_l${userLevel}` as keyof EarningInterface];
            percentage = (numberOfUserDownlines / LEVEL_1_THRESHOLD) * 100;
            break;
        case numberOfUserDownlines <= LEVEL_2_THRESHOLD:
            userLevel = 2;
            reward = 250;
            friends_to_inv = LEVEL_2_THRESHOLD;
            can_claim = numberOfUserDownlines >= LEVEL_2_THRESHOLD;
            claimed =
                earning?.[`claimed_l${userLevel}` as keyof EarningInterface];
            percentage = calculatePercentage(
                numberOfUserDownlines,
                LEVEL_1_THRESHOLD,
                LEVEL_2_THRESHOLD
            );
            break;
        case numberOfUserDownlines <= LEVEL_3_THRESHOLD:
            userLevel = 3;
            reward = 600;
            friends_to_inv = LEVEL_3_THRESHOLD;
            can_claim = numberOfUserDownlines >= LEVEL_3_THRESHOLD;
            claimed =
                earning?.[`claimed_l${userLevel}` as keyof EarningInterface];
            percentage = calculatePercentage(
                numberOfUserDownlines,
                LEVEL_2_THRESHOLD,
                LEVEL_3_THRESHOLD
            );
            break;
        case numberOfUserDownlines <= LEVEL_4_THRESHOLD:
            userLevel = 4;
            reward = 1200;
            friends_to_inv = LEVEL_4_THRESHOLD;
            can_claim = numberOfUserDownlines >= LEVEL_4_THRESHOLD;
            claimed =
                earning?.[`claimed_l${userLevel}` as keyof EarningInterface];
            percentage = calculatePercentage(
                numberOfUserDownlines,
                LEVEL_3_THRESHOLD,
                LEVEL_4_THRESHOLD
            );
            break;
        case numberOfUserDownlines <= LEVEL_5_THRESHOLD:
            userLevel = 5;
            reward = 3000;
            friends_to_inv = LEVEL_5_THRESHOLD;
            can_claim = numberOfUserDownlines >= LEVEL_5_THRESHOLD;
            claimed =
                earning?.[`claimed_l${userLevel}` as keyof EarningInterface];

            percentage = calculatePercentage(
                numberOfUserDownlines,
                LEVEL_4_THRESHOLD,
                LEVEL_5_THRESHOLD
            );
            break;
        case numberOfUserDownlines <= LEVEL_6_THRESHOLD:
            userLevel = 6;
            reward = 6000;
            friends_to_inv = LEVEL_6_THRESHOLD;
            can_claim = numberOfUserDownlines >= LEVEL_6_THRESHOLD;
            claimed =
                earning?.[`claimed_l${userLevel}` as keyof EarningInterface];
            percentage = calculatePercentage(
                numberOfUserDownlines,
                LEVEL_5_THRESHOLD,
                LEVEL_6_THRESHOLD
            );
            break;
        case numberOfUserDownlines <= LEVEL_7_THRESHOLD:
            userLevel = 7;
            reward = 15000;
            friends_to_inv = LEVEL_7_THRESHOLD;
            can_claim = numberOfUserDownlines >= LEVEL_7_THRESHOLD;
            claimed =
                earning?.[`claimed_l${userLevel}` as keyof EarningInterface];
            percentage = calculatePercentage(
                numberOfUserDownlines,
                LEVEL_6_THRESHOLD,
                LEVEL_7_THRESHOLD
            );
            break;
        default:
            userLevel = 0;
            reward = 100;
            friends_to_inv = 1;
            can_claim = numberOfUserDownlines >= 1;
            claimed =
                earning?.[`claimed_l${userLevel}` as keyof EarningInterface];
            percentage = (numberOfUserDownlines / LEVEL_1_THRESHOLD) * 100;
            break;
    }

    return {
        userLevel,
        reward,
        friends_to_inv,
        can_claim,
        claimed,
        percentage,
    };
}
