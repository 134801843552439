import React from "react";
import ReactLoading, { LoadingType } from "react-loading";

interface CustomeLoadingProps {
    type?: LoadingType;
    color?: string;
    delay?: number;
    height?: string | number;
    width?: string | number;
    className?: string;
}
const CustomLoading = ({
    type = "spinningBubbles",
    color = "white",
    height = 30,
    width = 30,
}: CustomeLoadingProps) => {
    return (
        <ReactLoading type={type} color={color} height={height} width={width} />
    );
};

export default CustomLoading;
