import { createSlice } from "@reduxjs/toolkit";
import { withdraw } from "./WithdrawActions";

export interface WithdrawState {
    withdrawIsLoading: boolean;
    withdrawError?: string | null | unknown;
    withdrawalResponse: string;
}

const initialState: WithdrawState = {
    withdrawIsLoading: false,
    withdrawError: null,
    withdrawalResponse: "",
};

const withdrawalSlice = createSlice({
    name: "withdrawal",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(withdraw.pending, (state) => {
                state.withdrawIsLoading = true;
            })
            .addCase(withdraw.fulfilled, (state, action) => {
                state.withdrawIsLoading = false;
                state.withdrawError = null;
                state.withdrawalResponse = action.payload.data;
            })
            .addCase(withdraw.rejected, (state, action) => {
                state.withdrawIsLoading = false;
                state.withdrawError = action.payload;
            });
    },
});

export default withdrawalSlice.reducer;
