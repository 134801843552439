import React from "react";
import { useGetMachines, useGetOwnedMachines } from "./useMachines";
import PageLoading from "../../components/PageLoading/PageLoading";
import MachineItem from "../../components/MachineItem/MachineItem";
import styled from "styled-components";
import { MachineInterface } from "./redux/MachineSlice";

const MachineListStyle = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    gap: 12px;
`;

const Machines = () => {
    const { machines, MachinesIsLoading } = useGetMachines();
    const { ownedMachines } = useGetOwnedMachines();

    // Function to check if a machine is owned
    const isMachineOwned = (machine: MachineInterface) => {
        return ownedMachines.some(
            (ownedMachine) => ownedMachine.machine_set.id === machine.id
        );
    };

    if (MachinesIsLoading) {
        return <PageLoading />;
    }

    return (
        <div className="page-content">
            <MachineListStyle>
                {machines.map((machine, index) => (
                    <MachineItem
                        key={index}
                        machine={machine}
                        owned={isMachineOwned(machine)}
                    />
                ))}
            </MachineListStyle>
        </div>
    );
};

export default Machines;
