import React from "react";
import WalletActions from "../../components/WalletActions";
import BalanceList from "../../components/BalanceList";
import { useWallet } from "./hooks/useWallet";

const Wallet = () => {
    const { balanceList } = useWallet();

    return (
        <div className="page-content">
            <WalletActions />
            <BalanceList balanceList={balanceList} />
        </div>
    );
};

export default Wallet;
