import React, { useEffect } from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { TfiWorld } from "react-icons/tfi";
import { FaYoutube } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { RootState, store } from "../../app/store";
import { getUsers } from "../../components/User/redux/UserActions";
import { useSelector } from "react-redux";
import CustomLoading from "../../components/CustomLoading/CustomLoading";

const CommunityStyles = styled.div`
    display: block;

    & > .background {
        width: 100%;
        max-width: 500px;
        height: 200px;
        background-image: url("https://i.gifer.com/7Nth.gif");
        position: fixed;
        bottom: 0;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
    }

    & > .header {
        text-align: left;
        margin-top: 40px;
    }

    & > .sicial-list {
        display: flex;
        align-items: center;
        gap: 16px;

        & > .item {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px;
            border-radius: 50%;
            border: 1px solid ${({ theme }) => theme.colors.text};
            background-color: ${({ theme }) => theme.colors.text};

            & > svg {
                color: ${({ theme }) => theme.colors.secondary};
            }
        }
    }

    & > .stats {
        display: flex;
        flex-direction: column;
        margin-block: 40px;
        gap: 20px;

        & > .user-online {
            display: flex;

            & > div {
                border-left: thin solid;
                border-image: linear-gradient(
                        to bottom,
                        ${({ theme }) => theme.colors.text},
                        ${({ theme }) => theme.colors.secondary}
                    )
                    1;
                width: 50%;
                padding: 0px 8px;
                font-size: 20px;
                font-weight: lighter;
                color: ${({ theme }) => theme.colors.primary};

                & > h3 {
                    line-height: 1;
                    color: ${({ theme }) => theme.colors.text};
                }
            }
        }
        & > .other-stats {
            border-left: thin solid;
            border-image: linear-gradient(
                    to bottom,
                    ${({ theme }) => theme.colors.text},
                    ${({ theme }) => theme.colors.secondary}
                )
                1;
            padding: 0px 8px;
            font-size: 20px;
            font-weight: lighter;
            color: ${({ theme }) => theme.colors.primary};

            & > h3 {
                line-height: 1;
                color: ${({ theme }) => theme.colors.text};
            }
        }
    }
`;

const socialList = [
    {
        name: "Web",
        icon: <TfiWorld />,
        link: "https://tronstack.network/",
    },
    {
        name: "Telegram",
        icon: <FaTelegramPlane />,
        link: "https://t.me/tronstacknetwork",
    },
    {
        name: "Twitter",
        icon: <FaXTwitter />,
        link: "https://x.com/tronstack",
    },
    {
        name: "YouTube",
        icon: <FaYoutube />,
        link: "https://youtube.com/tronstack",
    },
    {
        name: "Facebook",
        icon: <FaFacebookF />,
        link: "https://facebook.com/tronstack",
    },
];

const Community = () => {
    const { users, getUsersIsLoading } = useSelector(
        (state: RootState) => state.user
    );

    useEffect(() => {
        store.dispatch(getUsers({ empty: "" }));
    }, []);

    return (
        <CommunityStyles>
            <h2 className="header">OUR COMMUNITY</h2>
            <div className="sicial-list">
                {socialList.map((socials, index) => {
                    return (
                        <Link
                            to={socials.link}
                            target="_blank"
                            key={index}
                            className="item"
                        >
                            {socials.icon}
                        </Link>
                    );
                })}
            </div>
            <div className="stats">
                <div className="user-online">
                    <div>
                        <div>Total</div>
                        <div>User</div>
                        <h3>
                            {getUsersIsLoading ? (
                                <CustomLoading />
                            ) : (
                                users.length + 1000
                            )}
                        </h3>
                    </div>
                    <div>
                        {/* <div>Online</div>
                        <div>Now</div>
                        <h3>2,483,225</h3> */}
                    </div>
                </div>
                <div className="other-stats">
                    <div>Total Machine Available</div>
                    <h3>47,957</h3>
                </div>
                <div className="other-stats">
                    <div>Total Machine Sold</div>
                    <h3>2,043</h3>
                </div>
            </div>
            {/* <div className="background"></div> */}
        </CommunityStyles>
    );
};

export default Community;
