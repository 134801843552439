import React, { useState } from "react";
import { ButtonVariant } from "../Button/ButoonInteface";
import Button from "../Button";
import { StreakContainer } from "./Streak.styled";
import CustomLoading from "../CustomLoading/CustomLoading";

const Streak = () => {
    const [claimStreakLoading, setClaimStreakLoading] = useState(false);
    return (
        <StreakContainer>
            <div
                style={{
                    flexDirection: "column",
                }}
            >
                <h3>STREAK</h3>
                <h1 style={{ fontSize: "50px", lineHeight: 1 }}>0</h1>
            </div>
            <div>
                <Button
                    variant={ButtonVariant.Primary}
                    style={{ width: "140px" }}
                    onClick={() => {
                        setClaimStreakLoading(true);
                        setTimeout(() => {
                            setClaimStreakLoading(false);
                        }, 3000);
                    }}
                >
                    {claimStreakLoading ? (
                        <CustomLoading height={20} width={20} />
                    ) : (
                        "Receive 5TRX"
                    )}
                </Button>
            </div>
        </StreakContainer>
    );
};

export default Streak;
