import { createSlice } from "@reduxjs/toolkit";
import { register } from "./registerAction";
import { REGISTER_STATE_INTERFACE } from "../interface/register";

const initialState: REGISTER_STATE_INTERFACE = {
    registerIsLoading: false,
    registerError: null,
    registerSuccess: false,
};

const registerSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(register.pending, (state) => {
                state.registerIsLoading = true;
                state.registerError = null;
            })
            .addCase(register.fulfilled, (state, action) => {
                state.registerIsLoading = false;
                state.registerError = null;
            })
            .addCase(register.rejected, (state, action) => {
                state.registerIsLoading = false;
                state.registerError = action.payload;
            });
    },
});

export default registerSlice.reducer;
