import React from "react";
import CustomLoading from "../CustomLoading/CustomLoading";

interface PageLoadingProps {
    transparentBackground?: boolean;
}

const PageLoading = ({ transparentBackground }: PageLoadingProps) => {
    return (
        <div
            className="page-container"
            style={{
                backgroundColor: `${transparentBackground && "transparent"}`,
                position: `${transparentBackground ? "absolute" : "relative"}`,
                left: `${transparentBackground && "50%"}`,
                top: "50%",
                transform: `${
                    transparentBackground && "translate(-50%, -50%)"
                }`,
                height: "100dvh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <CustomLoading width={50} height={50} />
        </div>
    );
};

export default PageLoading;
