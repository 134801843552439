import React from "react";
import { useHistory } from "./useHistory";
import styled from "styled-components";
import HistoryItem from "../../components/HistoryItem/HistoryItem";
import PageLoading from "../../components/PageLoading/PageLoading";
import EmptyList from "../../components/EmptyList/EmptyList";

const History = () => {
    const { userHistories, userHistoryIsLoading } = useHistory();

    if (userHistoryIsLoading) {
        return <PageLoading transparentBackground={true} />;
    }

    if (!userHistories.length) {
        return <EmptyList message={"No History"} />;
    }

    return (
        <div className="page-content">
            <HistoryList>
                {userHistories.map((history, index) => (
                    <HistoryItem key={index} history={history} />
                ))}
            </HistoryList>
        </div>
    );
};

const HistoryList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

export default History;
