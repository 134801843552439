import React from "react";
import { FooterContainer, StyledLink } from "./Footer.styled";
import { IoMdHome, IoMdSettings } from "react-icons/io";
import { FaWallet } from "react-icons/fa6";
import { LuNetwork } from "react-icons/lu";
import { GiMining } from "react-icons/gi";

interface MENUSINTERFACE {
    name: string;
    link: string;
    icon: JSX.Element;
}

const MENUS = [
    {
        name: "Home",
        link: "/",
        icon: <IoMdHome size={30} />,
    },
    {
        name: "Machines",
        link: "machines",
        icon: <GiMining size={30} />,
    },
    {
        name: "Wallet",
        link: "wallet",
        icon: <FaWallet size={30} />,
    },
    {
        name: "Network",
        link: "network",
        icon: <LuNetwork size={30} />,
    },
    {
        name: "Settings",
        link: "settings",
        icon: <IoMdSettings size={30} />,
    },
] as MENUSINTERFACE[];

const Footer = () => {
    return (
        <FooterContainer id="footer">
            {MENUS.map((menu, index) => (
                <StyledLink
                    to={menu.link}
                    key={index}
                    activeClassName="active"
                    aria-label={menu.name}
                >
                    {menu.icon}
                    <div>{menu.name}</div>
                </StyledLink>
            ))}
        </FooterContainer>
    );
};

export default Footer;
