import React from "react";
import { MdOutlineFileDownload, MdOutlineFileUpload } from "react-icons/md";
import styled from "styled-components";
import { LiaHistorySolid } from "react-icons/lia";
import WalletActionItems from "../WalletActionItems";
import { GiStakeHammer } from "react-icons/gi";
import { GrSend } from "react-icons/gr";
import { walletItemsAction } from "../WalletActionItems/WalletActionItems";
import { useNavigate } from "react-router-dom";
import { RootState, store } from "../../app/store";
import { createDeposit } from "../../pages/deposit/redux/DepositActions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { toastOption } from "../../constants";

const WalletActions = () => {
    const navigate = useNavigate();
    const { user } = useSelector((state: RootState) => state.user);
    const actionList: walletItemsAction[] = [
        {
            name: "Deposit",
            icon: <MdOutlineFileDownload size={30} />,
            handleClick: () => {
                if (localStorage.getItem("endTime")) {
                    navigate(`/deposit/${localStorage.getItem("depositId")}`);
                } else {
                    store
                        .dispatch(createDeposit({ user: user.id }))
                        .unwrap()
                        .then((res) => {
                            navigate(`/deposit/${res.data.id}`);
                            localStorage.setItem("depositId", res.data.id);
                        })
                        .catch((res) => console.log(res));
                }
            },
        },
        {
            name: "Send",
            icon: <GrSend size={30} />,
            handleClick: () =>
                toast.info("Send feature coming soon!", toastOption),
        },
        {
            name: "History",
            icon: <LiaHistorySolid size={30} />,
            handleClick: () => navigate("/history"),
        },
        {
            name: "Withdraw",
            icon: <MdOutlineFileUpload size={30} />,
            handleClick: () => navigate("/withdraw"),
        },
        {
            name: "Stake",
            icon: <GiStakeHammer size={30} />,
            handleClick: () =>
                toast.info("Stake feature coming soon!", toastOption),
        },
    ];

    return (
        <WalletActionsStyles>
            {actionList.map((action, index) => (
                <WalletActionItems key={index} action={action} />
            ))}
        </WalletActionsStyles>
    );
};

const WalletActionsStyles = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-block: 70px;
`;

export default WalletActions;
