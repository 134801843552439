import styled from "styled-components";

export const StyleInput = styled.input<{ error: boolean }>`
    border: ${(props) =>
        props.error
            ? `thin solid ${props.theme.colors.error}`
            : props.theme.inputs.border};
    border-radius: ${(props) => props.theme.inputs.borderRadius}px;
    height: ${(props) => props.theme.inputs.height}px;
    background-color: ${(props) => props.theme.inputs.backgroundColor};
    color: ${(props) => props.theme.inputs.color};
    font-size: ${(props) => props.theme.font.size}px;
    font-family: ${(props) => props.theme.inputs.fontFamily};
    outline: none;

    &:focus {
        border: ${(props) =>
            props.error
                ? `thin solid ${props.theme.colors.error}`
                : props.theme.inputs.focus.border} !important;
    }

    &:disabled {
        color: grey;
    }
`;
