import React from "react";
import { WALLET_BALANCE_OBJECT } from "../../pages/wallet/hooks/useWallet";
import BalanceItem from "../BalanceItem";
import styled from "styled-components";

export interface BalanceListProps {
    balanceList: WALLET_BALANCE_OBJECT[];
}

const BalanceList = ({ balanceList }: BalanceListProps) => {
    return (
        <BalanceListStyle>
            <div>
                <h2>Balance</h2>
            </div>
            {balanceList.map((balance, index) => (
                <BalanceItem key={index} balance={balance} />
            ))}
        </BalanceListStyle>
    );
};

const BalanceListStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-block: 30px;
    margin-inline: -24px;

    & > div:first-child {
        display: flex;
        justify-content: center;
        padding: 20px;
        border-block: ${({ theme }) => `thin solid ${theme.colors.primary}`};
        background-image: linear-gradient(
            ${(props) => props.theme.colors.secondary},
            ${(props) => props.theme.colors.background} 45%
        );

        & > h2 {
            line-height: 0px;
        }
    }
`;

export default BalanceList;
