import React from "react";
import { FaCopy } from "react-icons/fa6";
import { MdOfflineBolt } from "react-icons/md";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Input from "../../components/Input";
import Button from "../../components/Button";
import copyToClipboard from "../../utils/copyToClipboard";
import { ButtonVariant } from "../../components/Button/ButoonInteface";
import QRCode from "react-qr-code";
import { useUserDownlines } from "../../components/User/hooks/useUser";
import CustomLoading from "../../components/CustomLoading/CustomLoading";

const NetworkStyle = styled.div`
    & > .top-card {
        padding-block: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: ${({ theme }) => theme.colors.secondary}50;
    }

    & > .middle-info {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        & > svg {
            color: ${({ theme }) => theme.colors.primary};
        }

        & > p {
            color: ${({ theme }) => theme.colors.secondary};
        }
    }

    & > .ref-details {
        padding-block: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: ${({ theme }) => theme.colors.secondary}50;

        & > .ref-link {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            width: 100%;

            & > input {
                width: 70%;
                color: #ffffff !important;
            }
        }
    }
`;

const Network = () => {
    const { userDownlinesIsLoading, userDownlines, user } = useUserDownlines();

    return (
        <NetworkStyle className="page-content">
            <div className="top-card">
                <h2>YOUR NETWORK</h2>
                <div>
                    <h1>
                        {userDownlinesIsLoading ? (
                            <CustomLoading />
                        ) : (
                            userDownlines.length
                        )}
                    </h1>
                </div>
                <Link to="/network-list">View details</Link>
            </div>
            <div className="middle-info">
                <MdOfflineBolt size={30} />
                <p>
                    Maximize your data network:
                    <br /> More nodes, faster mining!
                </p>
            </div>
            <div className="ref-details">
                <div>Expand Your Network Now!</div>
                <div className="ref-link">
                    <Input
                        value={`${user.referral_code}`}
                        error={false}
                        disabled
                    />
                    <Button
                        variant={ButtonVariant.Primary}
                        onClick={() =>
                            copyToClipboard(
                                user.referral_code!,
                                "Referal code copied to clipboard"
                            )
                        }
                    >
                        <FaCopy />
                    </Button>
                </div>
                <div className="qr-container">
                    <QRCode
                        value={user.referral_code ? user.referral_code : ""}
                        bgColor={"#F0F0F0"}
                        fgColor={"#000000"}
                        size={200}
                    />
                </div>
            </div>
        </NetworkStyle>
    );
};

export default Network;
