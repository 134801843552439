import styled from "styled-components";

export const StyledButton = styled.button<{
    variant: string;
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ variant, theme }) =>
        variant === "secondary"
            ? theme.buttons.secondary.color
            : theme.buttons.primary.color};
    background-color: ${({ variant, theme }) =>
        variant === "secondary"
            ? theme.buttons.secondary.backgroundColor
            : theme.buttons.primary.backgroundColor};
    border: none;
    padding: ${({ theme }) => theme.spacing.padding.medium}px
        ${({ theme }) => theme.spacing.padding.large}px;
    border-radius: ${({ theme }) => theme.buttons.borderRadius}px;
    margin-block: ${({ theme }) => theme.buttons.marginBlock}px;
    height: ${({ theme }) => theme.buttons.height}px;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.3s ease;

    &:hover {
        
    }

    &:disabled {
        color: ${({ variant, theme }) =>
            variant === "secondary"
                ? theme.buttons.secondary.disabled.color
                : theme.buttons.primary.disabled.color};
        background-color: ${({ variant, theme }) =>
            variant === "secondary"
                ? theme.buttons.secondary.disabled.backgroundColor
                : theme.buttons.primary.disabled.backgroundColor};
        cursor: not-allowed;
    }
`;
