import React from "react";
import styled from "styled-components";

export interface walletItemsAction {
    name: string;
    icon: JSX.Element;
    handleClick: () => void;
}
export interface WalletActionItemsProps {
    key?: number;
    action: walletItemsAction;
}

const WalletActionItems = ({ action }: WalletActionItemsProps) => {
    return (
        <WalletActionItemsStyle
            // to={action.link}
            onClick={() => action.handleClick()}
        >
            <div className="icon">{action.icon}</div>
            <div className="text">{action.name}</div>
        </WalletActionItemsStyle>
    );
};

const WalletActionItemsStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% / 3);
    cursor: pointer;

    & > .icon {
        display: flex;
        height: 30px;
        width: 30px;
        padding: 15px;
        margin: 15px 15px 0;
        color: ${({ theme }) => `${theme.colors.primary}`};
        border: ${({ theme }) => `thin solid ${theme.colors.secondary}`};
        border-radius: 50%;
    }

    & > .text {
        font-weight: bold;
        color: ${({ theme }) => `${theme.colors.primary}`};
    }
`;

export default WalletActionItems;
