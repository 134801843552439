import React from "react";
import styled from "styled-components";
import { USER_HISTORY } from "../../pages/history/redux/HistorySlice";
import { LiaHistorySolid } from "react-icons/lia";
import { format } from "date-fns";
import { MdOutlineFileDownload, MdOutlineFileUpload } from "react-icons/md";
import { GiStakeHammer } from "react-icons/gi";

const HistoryItem = ({ history }: { history: USER_HISTORY }) => {
    let historyIcon;
    switch (history.tx_type) {
        case "deposit":
            historyIcon = <MdOutlineFileDownload size={20} />;
            break;
        case "withdraw":
            historyIcon = <MdOutlineFileUpload size={20} />;
            break;
        case "stake":
            historyIcon = <GiStakeHammer size={20} />;
            break;

        default:
            historyIcon = <LiaHistorySolid size={20} />;
            break;
    }
    return (
        <HistoryItemStyle>
            <div>
                <div>{historyIcon}</div>
                <div>
                    <div>{history.subject}</div>
                    <div>{history.message}</div>
                    <small style={{ opacity: 0.5 }}>
                        {format(new Date(history.date), "HH:mm:ss dd/MM/yyyy")}
                    </small>
                </div>
            </div>
            <div>
                <div>{history.amount} TRX</div>
            </div>
        </HistoryItemStyle>
    );
};

const HistoryItemStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-inline: -24px;
    padding: 10px 24px 5px;
    border-bottom: ${({ theme }) => `thin solid ${theme.colors.primary}`};

    & > div:first-child {
        display: flex;
        align-items: center;
        gap: 10px;

        & > div:first-child {
            padding: 8px 8px 4px 8px;
            height: fit-content;
            width: fit-content;
            background-color: ${({ theme }) => theme.colors.secondary};
        }
    }
`;

export default HistoryItem;
