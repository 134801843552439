import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface HomeGridCardProps {
    name: string;
    link: string;
    icon: JSX.Element;
}

const HomeGridCard = ({ name, link, icon }: HomeGridCardProps) => {
    const navigate = useNavigate();
    return (
        <HomeGridCardContainer onClick={() => navigate(link)}>
            {icon}
            <h3>{name}</h3>
        </HomeGridCardContainer>
    );
};

const HomeGridCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: ${({ theme }) => `thin solid ${theme.colors.primary}`};
    padding: 10px;
    color: ${({ theme }) => theme.colors.primary};
    height: 70px;
    cursor: pointer;

    & > h3 {
        font-weight: bold;
    }
`;

export default HomeGridCard;
