import React, { useState } from "react";
import { useBuyMachine } from "./useBuyMachine";
import { ButtonVariant } from "../../components/Button/ButoonInteface";
import Button from "../../components/Button";
import { buyMachine } from "../machines/redux/MachineActions";
import { store } from "../../app/store";
import { toast } from "react-toastify";
import { toastOption } from "../../constants";
import styled from "styled-components";
import CustomLoading from "../../components/CustomLoading/CustomLoading";

const BuyMachineStyle = styled.div`
    & > .owned-tag {
        position: absolute;
        padding: 2px;
        background-color: ${({ theme }) => theme.colors.primary};
    }

    & > img {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    & > .headline,
    & > .sub-headline {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > .title {
            color: ${({ theme }) => theme.colors.primary};
        }

        & > div {
            display: flex;

            & > button {
                height: 30px;
            }
            
            & > div {
                display: flex;
                width: 20px !important;
                justify-content: center;
                align-items: center;
            }
        }
    }

    & > button {
    }
`;

const BuyMachine = () => {
    const { state, user, navigate } = useBuyMachine();
    const [quantity, setQuantity] = useState(1);
    const [buyIsLoading, setBuyIsLoading] = useState(false);
    return (
        <BuyMachineStyle className="page-content">
            <img src={state.machine.image} alt={state.machine.name} />
            <div className="headline">
                <div className="title">{state.machine.name}</div>{" "}
                <div>{state.machine.price}TRX</div>
            </div>
            <div className="sub-headline">
                <div>Daily income</div>
                <div>{state.machine.daily_profit}TRX</div>
            </div>
            <div className="sub-headline">
                <div>Max buy</div>
                <div>
                    <Button
                        variant={ButtonVariant.Primary}
                        onClick={() => {
                            if (quantity <= 1) return;
                            setQuantity((quantity) => quantity - 1);
                        }}
                        disabled={quantity <= 1}
                    >
                        -
                    </Button>
                    <div>{quantity}</div>
                    <Button
                        variant={ButtonVariant.Primary}
                        onClick={() => {
                            if (quantity >= state.machine.max_quantity) return;
                            setQuantity((quantity) => quantity + 1);
                        }}
                        disabled={quantity >= state.machine.max_quantity}
                    >
                        +
                    </Button>
                </div>
            </div>
            <Button
                variant={ButtonVariant.Primary}
                style={{ width: "100%" }}
                onClick={() => {
                    setBuyIsLoading(true);
                    store
                        .dispatch(
                            buyMachine({
                                user: user.id,
                                machine: state.machine.id,
                                quantity: quantity,
                            })
                        )
                        .unwrap()
                        .then(() => {
                            setBuyIsLoading(false);
                            toast.success(
                                "Machine buy successful.",
                                toastOption
                            );
                            navigate("/machines");
                        })
                        .catch((error) => {
                            setBuyIsLoading(false);
                            toast.error(`${error}`, toastOption);
                        });
                }}
                disabled={buyIsLoading}
            >
                {buyIsLoading ? <CustomLoading /> : "Buy"}
            </Button>
        </BuyMachineStyle>
    );
};

export default BuyMachine;
