import { FormikProps } from "formik";
import React from "react";
import Button from "../../../components/Button";
import { ButtonVariant } from "../../../components/Button/ButoonInteface";
import CustomLoading from "../../../components/CustomLoading/CustomLoading";
import Input from "../../../components/Input";

interface SetWithdrawalPinFormProps {
    formik: FormikProps<{
        user: string | number | undefined;
        pin: string;
        password: string;
    }>;
    setWithdrawalPinIsLoading: boolean;
    setWithdrawalPinError: any;
}
const SetWithdrawalPinForm = ({
    formik,
    setWithdrawalPinIsLoading,
    setWithdrawalPinError,
}: SetWithdrawalPinFormProps) => {
    return (
        <form
            onSubmit={formik.handleSubmit}
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
            <h1>Set Withdrawal Pin</h1>
            <div className="form-group">
                <label htmlFor="password">Pin</label>
                <Input
                    type="password"
                    inputMode="numeric"
                    maxLength={4}
                    className="form-control"
                    id="pin"
                    name="pin"
                    placeholder="Pin"
                    onChange={formik.handleChange}
                    value={formik.values.pin}
                    error={
                        formik.errors?.pin || setWithdrawalPinError?.pin
                            ? true
                            : false
                    }
                />
                <div className="form-error">
                    {formik.errors?.pin || setWithdrawalPinError?.pin}
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="password">Password</label>
                <Input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    placeholder="Enter your password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    error={
                        formik.errors?.password ||
                        setWithdrawalPinError?.password
                            ? true
                            : false
                    }
                />
                <div className="form-error">
                    {formik.errors?.password || setWithdrawalPinError?.password}
                </div>
            </div>
            <Button variant={ButtonVariant.Primary}>
                {setWithdrawalPinIsLoading ? <CustomLoading /> : "Set Pin"}
            </Button>
        </form>
    );
};

export default SetWithdrawalPinForm;
