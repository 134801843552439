import { Bounce, ToastOptions } from "react-toastify";

export const EXPIRES_IN = 3600000 / 60 * 60

export const MIN_AMOUNT = 100
export const WITHDRAWAL_PERCENTAGE = 0
export const WITHDRAWAL_CHARGE_FEE = 20

export const toastOption:ToastOptions = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
}