import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const endPoint = process.env.REACT_APP_API_URL;

export const getUserNotifications = createAsyncThunk(
    "notification/getUserNotifications",
    async ({ user_id }: { user_id?: string | number }, thunkApi) => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const res = await axios.get(
                `${endPoint}/notifications/?user=${user_id}`,
                config
            );
            return thunkApi.fulfillWithValue(res.data);
        } catch (error: any) {
            return thunkApi.rejectWithValue(error.response?.data);
        }
    }
);

export const notificationMarkAllAsRead = createAsyncThunk(
    "notification/notificationMarkAllAsRead",
    async ({ user_id }: { user_id?: string | number }, thunkApi) => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const res = await axios.post(
                `${endPoint}/notification_mark_all_as_read/`,
                {},
                config
            );
            thunkApi.dispatch(getUserNotifications({ user_id: user_id }));
            return thunkApi.fulfillWithValue(res.data);
        } catch (error: any) {
            return thunkApi.rejectWithValue(await error.response.data);
        }
    }
);