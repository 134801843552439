import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState, store } from "../../app/store";
import { useEffect } from "react";
import { getMachines, getOwnedMachines } from "./redux/MachineActions";
import useLocalStorage from "../../utils/useLocalStorage";

export const useGetMachines = () => {
    const navigate = useNavigate();
    const { machines, MachinesIsLoading, MachinesError } = useSelector(
        (state: RootState) => state.machine
    );
    // const { minersOwned } = useSelector((state) => state.minersOwned);

    // const { user } = useSelector((state: RootState) => state.user);

    const [displayType, setDisplayType] = useLocalStorage(
        "displayType",
        "list"
    );

    useEffect(() => {
        store.dispatch(getMachines({ empty: "" }));
        // store.dispatch(getMinersOwned({ user: user }));
    }, []);

    return {
        navigate,
        // minersOwned,
        machines,
        MachinesIsLoading,
        MachinesError,
        displayType,
        setDisplayType,
    };
};

export const useGetOwnedMachines = () => {
    const navigate = useNavigate();
    const { ownedMachines, ownedMachinesIsLoading, ownedMachinesError } =
        useSelector((state: RootState) => state.machine);
    const { user } = useSelector((state: RootState) => state.user);

    const [displayType, setDisplayType] = useLocalStorage(
        "displayType",
        "list"
    );

    useEffect(() => {
        store.dispatch(getOwnedMachines({ user_id: user.id! }));
    }, [user.id]);

    return {
        navigate,
        ownedMachines,
        displayType,
        setDisplayType,
        ownedMachinesIsLoading,
        ownedMachinesError,
    };
};
