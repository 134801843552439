import React from "react";
import { HomeGridLinkContainer } from "./HomeGridLink.styled";
import HomeGridCard from "../HomeGridCard/HomeGridCard";
import { TiWorldOutline } from "react-icons/ti";
import { GiMining } from "react-icons/gi";
import { LuNetwork } from "react-icons/lu";
// import { FaChartBar } from "react-icons/fa6";

const HomeGridLink = () => {
    const gridLinks = [
        {
            name: "Community",
            link: "/community",
            icon: <TiWorldOutline size={50} />,
        },
        {
            name: "Mining",
            link: "/mining",
            icon: <GiMining size={30} />,
        },
        {
            name: "Network",
            link: "/network",
            icon: <LuNetwork size={30} />,
        },
        // {
        //     name: "Leaderboard",
        //     link: "/leaderboard",
        //     icon: <FaChartBar size={30} />,
        // },
    ];
    return (
        <HomeGridLinkContainer>
            {gridLinks.map((linkData, index) => (
                <HomeGridCard
                    key={index}
                    name={linkData.name}
                    link={linkData.link}
                    icon={linkData.icon}
                />
            ))}
        </HomeGridLinkContainer>
    );
};

export default HomeGridLink;
