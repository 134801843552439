import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import TopNav from "../../components/TopNav/TopNav";
import { store } from "../../app/store";
import { getUser } from "../../components/User/redux/UserActions";
import { useGetUser } from "../../components/User/hooks/useUser";

const MainLayout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useGetUser();

    useEffect(() => {
        if (user.full_name === "") {
            navigate("/profile");
        }
    }, [navigate, user.full_name]);

    useEffect(() => {
        store.dispatch(getUser({ empty: "" }));
    }, [dispatch]);

    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            navigate("/login");
        }
    }, [navigate]);

    return (
        <div className="container">
            <TopNav />
            <Outlet />
            <Footer />
        </div>
    );
};

export default MainLayout;
