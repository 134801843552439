import React from "react";
import Button from "../../components/Button";
import Input from "../../components/Input/Input";
import { useRegister } from "./hooks/useRegister";
import { Link } from "react-router-dom";
import { ButtonVariant } from "../../components/Button/ButoonInteface";
import CustomLoading from "../../components/CustomLoading/CustomLoading";

const Register = () => {
    const { formik, registerIsLoading, registerError } = useRegister();
    return (
        <div className="container">
            <h1 style={{ marginBlock: "30px 0px" }}>Register</h1>
            <form
                onSubmit={formik.handleSubmit}
                style={{ display: "flex", flexDirection: "column" }}
            >
                <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <Input
                        type="text"
                        id="username"
                        placeholder="Username"
                        name="username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        error={
                            formik.errors?.username || registerError?.username
                                ? true
                                : false
                        }
                    />
                    <div className="form-error">
                        {formik.errors?.username || registerError?.username}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Input
                        type="email"
                        id="email"
                        placeholder="Email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                            formik.errors?.email || registerError?.email
                                ? true
                                : false
                        }
                    />
                    <div className="form-error">
                        {formik.errors?.email || registerError?.email}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="password1">Password</label>
                    <Input
                        type="password"
                        id="password1"
                        placeholder="Password"
                        name="password1"
                        value={formik.values.password1}
                        onChange={formik.handleChange}
                        error={
                            formik.errors?.password1 || registerError?.password1
                                ? true
                                : false
                        }
                    />
                    <div className="form-error">
                        {formik.errors?.password1 || registerError?.password1}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="password2">Repeat Password</label>
                    <Input
                        type="password"
                        id="password2"
                        placeholder="Password"
                        name="password2"
                        value={formik.values.password2}
                        onChange={formik.handleChange}
                        error={
                            formik.errors?.password2 || registerError?.password2
                                ? true
                                : false
                        }
                    />
                    <div className="form-error">
                        {formik.errors?.password2 || registerError?.password2}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="referral">Referral Code</label>
                    <Input
                        type="text"
                        id="referral"
                        placeholder="Referral Code"
                        name="referral"
                        value={
                            formik.values.referral ? formik.values.referral : ""
                        }
                        onChange={formik.handleChange}
                        error={
                            formik.errors?.referral || registerError?.referral
                                ? true
                                : false
                        }
                    />
                    <div className="form-error">
                        {formik.errors?.referral || registerError?.referral}
                    </div>
                </div>
                <Button
                    type="submit"
                    variant={ButtonVariant.Primary}
                    disabled={registerIsLoading}
                >
                    {registerIsLoading ? <CustomLoading /> : "Register"}
                </Button>
            </form>
            <span style={{ margin: "auto" }}>
                Have an account? <Link to={"/login"}>Login</Link>
            </span>
        </div>
    );
};

export default Register;
