import React from "react";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import CustomLoading from "../../../components/CustomLoading/CustomLoading";
import { ButtonVariant } from "../../../components/Button/ButoonInteface";
import { FormikProps } from "formik";

interface ChangePasswordFormProps {
    formik: FormikProps<{
        old_password: string;
        new_password1: string;
        new_password2: string;
    }>;
    changePasswordIsLoading: boolean;
    changePasswordError: any;
}

const ChangePasswordForm = ({
    formik,
    changePasswordIsLoading,
    changePasswordError,
}: ChangePasswordFormProps) => {
    return (
        <form
            onSubmit={formik.handleSubmit}
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
            <h1>Change Password</h1>
            <div className="form-group">
                <label htmlFor="password">Current Password</label>
                <Input
                    type="password"
                    id="old_password"
                    placeholder="Current Password"
                    name="old_password"
                    value={formik.values.old_password}
                    onChange={formik.handleChange}
                    error={
                        formik.errors?.old_password
                            ? true
                            : false || changePasswordError?.old_password
                    }
                />
                <div className="form-error">
                    {formik.errors?.old_password ||
                        changePasswordError?.old_password}
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="new_password1">New Password</label>
                <Input
                    type="password"
                    className="form-control"
                    id="new_password1"
                    name="new_password1"
                    placeholder="New password"
                    onChange={formik.handleChange}
                    value={formik.values.new_password1}
                    error={
                        formik.errors?.new_password1 ||
                        changePasswordError?.new_password1
                            ? true
                            : false
                    }
                />
                <div className="form-error">
                    {formik.errors?.new_password1 ||
                        changePasswordError?.new_password1}
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="new_password2">Confirm New Password</label>
                <Input
                    type="password"
                    className="form-control"
                    id="new_password2"
                    name="new_password2"
                    placeholder="Re-enter new password"
                    onChange={formik.handleChange}
                    value={formik.values.new_password2}
                    error={
                        formik.errors?.new_password2 ||
                        changePasswordError?.new_password2
                            ? true
                            : false
                    }
                />
                <div className="form-error">
                    {formik.errors?.new_password2 ||
                        changePasswordError?.new_password2}
                </div>
            </div>
            <Button
                variant={ButtonVariant.Primary}
                style={{ marginTop: "auto" }}
            >
                {changePasswordIsLoading ? (
                    <CustomLoading />
                ) : (
                    "Change Password"
                )}
            </Button>
        </form>
    );
};

export default ChangePasswordForm;
