import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { slugify } from "../../../helper";

const endPoint = process.env.REACT_APP_API_URL;

export const getUser = createAsyncThunk(
    "getUser",
    async ({ empty }: { empty: string }, thunkApi) => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const authUser = JSON.parse(localStorage.getItem("user") as string);
            const res = await axios.get(
                `${endPoint}/users/${slugify(authUser.username)}/`,
                config
            );
            return thunkApi.fulfillWithValue(res);
        } catch (error: any) {
            return thunkApi.rejectWithValue(error.response.data);
        }
    }
);

export const updateUser = createAsyncThunk(
    "updateUser",
    async (
        {
            full_name,
            email,
            phone_number,
        }: { full_name?: string; email?: string; phone_number?: string },
        thunkApi
    ) => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const authUser = JSON.parse(localStorage.getItem("user") as string);
            const res = await axios.patch(
                `${endPoint}/users/${slugify(authUser.username)}/`,
                {
                    full_name,
                    email,
                    phone_number,
                },
                config
            );
            return thunkApi.fulfillWithValue(res);
        } catch (error: any) {
            return thunkApi.rejectWithValue(error.response.data);
        }
    }
);

export const getUserDownlines = createAsyncThunk(
    "getUserDownlines",
    async ({ referral_code }: { referral_code: string }, thunkApi) => {
        try {
            const config = {
                headers: {
                    // 'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const res = await axios.get(
                `${endPoint}/users/?referral=${referral_code}`,
                config
            );
            return thunkApi.fulfillWithValue(res);
        } catch (error: any) {
            return thunkApi.rejectWithValue(await error.response.data);
        }
    }
);

export const inviteErnings = createAsyncThunk(
    "invites/inviteErnings",
    async ({ user }: { user: number | string }, thunkApi) => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const res = await axios.get(
                `${endPoint}/invite-earnings/${user}/`,
                config
            );
            return thunkApi.fulfillWithValue(res);
        } catch (error: any) {
            return thunkApi.rejectWithValue(await error.response.data);
        }
    }
);

export const claimInviteErnings = createAsyncThunk(
    "invites/claimInviteErnings",
    async (
        {
            user,
            friends_to_inv,
        }: { user: number | string; friends_to_inv: number },
        thunkApi
    ) => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const res = await axios.post(
                `${endPoint}/invite-earnings/`,
                { user, friends_to_inv },
                config
            );
            return thunkApi.fulfillWithValue(res);
        } catch (error: any) {
            return thunkApi.rejectWithValue(await error.response.data);
        }
    }
);

export const getUsers = createAsyncThunk(
    "getUsers",
    async ({ empty }: { empty: string }, thunkApi) => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const res = await axios.get(`${endPoint}/users/`, config);
            return thunkApi.fulfillWithValue(res);
        } catch (error: any) {
            return thunkApi.rejectWithValue(error.response.data);
        }
    }
);

export const getContestants = createAsyncThunk(
    "getContestants",
    async ({ empty }: { empty: string }, thunkApi) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const res = await axios.get(`${endPoint}/contests/`, config);
            return res;
        } catch (error: any) {
            return thunkApi.rejectWithValue(await error.response.data);
        }
    }
);
