import { createAsyncThunk } from "@reduxjs/toolkit";

export const logout = createAsyncThunk(
    "logout",
    async ({ empty }: { empty: string }, { rejectWithValue }) => {
        try {
            const allThemes = localStorage.getItem("all-themes") as string;
            localStorage.clear();
            localStorage.setItem("all-themes", allThemes);
        } catch (error: any) {
            return rejectWithValue(error.response.data.message);
        }
    }
);
