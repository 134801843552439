import React from "react";
import Input from "../../../components/Input";
import { ButtonVariant } from "../../../components/Button/ButoonInteface";
import Button from "../../../components/Button";
import CustomLoading from "../../../components/CustomLoading/CustomLoading";
import { FormikProps } from "formik";

interface WithdrawFormProps {
    formik: FormikProps<{
        user: string | number | undefined;
        withdrawal_address: string;
        amount: string;
        pin: string;
    }>;
    withdrawIsLoading: boolean;
    withdrawError: any;
    handlePaste: () => void;
}

const WithdrawForm = ({
    formik,
    withdrawIsLoading,
    withdrawError,
    handlePaste,
}: WithdrawFormProps) => {
    return (
        <div>
            <form
                onSubmit={formik.handleSubmit}
                style={{ display: "flex", flexDirection: "column" }}
            >
                <div className="form-group">
                    <label htmlFor="withdrawal_address">
                        Withdrawal Address
                    </label>
                    <Input
                        type="text"
                        className="form-control"
                        id="withdrawal_address"
                        placeholder="Withdrawal address"
                        onChange={formik.handleChange}
                        value={formik.values.withdrawal_address}
                        error={
                            formik.errors.withdrawal_address ||
                            withdrawError?.withdrawal_address
                                ? true
                                : false
                        }
                    />
                    <div className="form-error">
                        {formik.errors.withdrawal_address ||
                            withdrawError?.withdrawal_address}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="amount">Amount</label>
                    <Input
                        type="number"
                        className="form-control"
                        id="amount"
                        placeholder="Amount"
                        inputMode="numeric"
                        name="amount"
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                        error={
                            formik.errors.amount || withdrawError?.amount
                                ? true
                                : false
                        }
                    />
                    <div className="form-error">
                        {formik.errors.amount || withdrawError?.amount}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="pin">Pin</label>
                    <Input
                        type="pin"
                        className="form-control"
                        id="pin"
                        placeholder="Pin"
                        inputMode="numeric"
                        name="pin"
                        value={formik.values.pin}
                        onChange={formik.handleChange}
                        error={
                            formik.errors.pin || withdrawError?.pin
                                ? true
                                : false
                        }
                    />
                    <div className="form-error">
                        {formik.errors.pin || withdrawError?.pin}
                    </div>
                </div>
                <Button
                    variant={ButtonVariant.Primary}
                    disabled={withdrawIsLoading}
                >
                    {withdrawIsLoading ? <CustomLoading /> : "Withdraw"}
                </Button>
            </form>
        </div>
    );
};

export default WithdrawForm;
