import React from "react";
import { useWithdraw } from "./useWithdraw";
import WithdrawForm from "./feature/WithdrawForm";

const Withdraw = () => {
    const { formik, withdrawIsLoading, withdrawError, handlePaste } =
        useWithdraw();
    return (
        <div className="page-content">
            <WithdrawForm
            formik={formik}
            withdrawIsLoading={withdrawIsLoading}
            withdrawError={withdrawError}
            handlePaste={handlePaste}
            />
        </div>
    );
};

export default Withdraw;
