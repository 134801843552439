import React from "react";
import { format } from "date-fns";
import { useNotification } from "./hooks/useNotification";

const Notification = () => {
    const { state } = useNotification();
    const notification = state?.notification;

    return (
        <div className="page-content">
            <h2
                style={{
                    color: "#00b894",
                }}
            >
                {notification?.subject}
            </h2>
            <div>
                <small style={{ opacity: 0.5 }}>
                    {notification?.date &&
                        format(notification?.date, "HH:mm dd/MM/yyyy")}
                </small>
            </div>
            <p style={{ opacity: 0.5 }}>{notification?.message}</p>
        </div>
    );
};

export default Notification;
