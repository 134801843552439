import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const endPoint = process.env.REACT_APP_API_URL;

export const getUserBalance = createAsyncThunk(
    "getUserBalance",
    async ({ user_id }: { user_id?: string | number }, thunkApi) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const res = await axios.get(
                `${endPoint}/wallets/${user_id}/`,
                config
            );
            return thunkApi.fulfillWithValue(res.data);
        } catch (error: any) {
            return thunkApi.rejectWithValue(error.response.data);
        }
    }
);
