import { useSelector } from "react-redux";
import { RootState, store } from "../../../app/store";
import { useEffect } from "react";
import { getUserNotifications } from "../redux/notificationsAction";
import { USERNOTIFICATION_OBJECT } from "../../notification/interface/notification";

export const useNotifications = () => {
    const {
        userNotifications,
        getUserNotificationsLoading,
        notificationMarkAllAsReadLoading,
    } = useSelector((state: RootState) => state.notification);
    const { user } = useSelector((state: RootState) => state.user);

    useEffect(() => {
        Object.keys(user).length &&
            store.dispatch(getUserNotifications({ user_id: user.id }));
    }, [user]);

    const hasUnreadNotification =
        userNotifications &&
        userNotifications.some(
            (notification: USERNOTIFICATION_OBJECT) => !notification.is_read
        );

    return {
        userNotifications,
        hasUnreadNotification,
        getUserNotificationsLoading,
        notificationMarkAllAsReadLoading,
    };
};
