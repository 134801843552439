import React, { InputHTMLAttributes } from "react";
import { StyleInput } from "./Input.styled";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    error: boolean;
}

const Input = ({ error, ...props }: InputProps) => {
    return <StyleInput error={error} {...props} />;
};

export default Input;
