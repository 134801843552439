import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { register } from "../redux/registerAction";
import { toastOption } from "../../../constants";
import { toast } from "react-toastify";
import { REGISTER_INTERFACE } from "../interface/register";
import { RootState, store } from "../../../app/store";

export const useRegister = () => {
    const { registerIsLoading, registerSuccess, registerError } = useSelector(
        (state: RootState) => state.register
    );

    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const referral = query.get("referral") as string;

    useEffect(() => {
        registerSuccess && navigate("/login");
    }, [navigate, registerSuccess]);

    const initialValues: REGISTER_INTERFACE = {
        username: "",
        email: "",
        password1: "",
        password2: "",
        referral: referral ? referral : "",
    };

    const onSubmit = (values: REGISTER_INTERFACE) => {
        store
            .dispatch(register(values))
            .unwrap()
            .then(() => {
                toast.success("Registration successful", toastOption);
                navigate("/login");
            })
            .catch((error) =>
                toast.error(`${error?.non_field_errors[0]}`, toastOption)
            );
    };

    const validationSchema = Yup.object({
        username: Yup.string()
            // .min(minUserChar, `Username must be ${minUserChar}`)
            .required("Username is required")
            .matches(/^\S*$/, "Username cannot contain spaces"),
        email: Yup.string().email().required("Email is required"),
        password1: Yup.string().required("Password1 is required"),
        password2: Yup.string()
            .required("Repeat Password is Required")
            .oneOf([Yup.ref("password1")], "Password Must Match"),
        referral: Yup.string().min(6).max(6),
        // .required("Referral code is required"),
    });

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });

    return {
        formik,
        registerIsLoading,
        registerError,
    };
};
