import { createSlice } from "@reduxjs/toolkit";
import { getMachines, getOwnedMachines } from "./MachineActions";

export interface MachineInterface {
    url: string;
    id: number;
    name: string;
    price: number;
    daily_profit: number;
    earning_sequence: number;
    image?: string;
    max_quantity: number;
    available: boolean;
    date_added: string;
    date_updated: string;
}

export interface OwnedMachineInterface {
    url: string;
    id: number;
    user: string;
    machine: number;
    machine_set: MachineInterface;
    equipment_code: string;
    order_type: string;
    quantity: number;
    is_activated: boolean;
    date: string;
}

export interface MachinesState {
    MachinesIsLoading: boolean;
    machines: MachineInterface[];
    MachinesError?: string | null | unknown;
    ownedMachines: OwnedMachineInterface[];
    ownedMachinesIsLoading: boolean;
    ownedMachinesError?: string | null | unknown;
}

const initialState: MachinesState = {
    MachinesIsLoading: false,
    machines: [],
    MachinesError: null,
    ownedMachines: [],
    ownedMachinesIsLoading: false,
    ownedMachinesError: null,
};

const machinesSlice = createSlice({
    name: "machines",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMachines.pending, (state) => {
                state.MachinesIsLoading = true;
            })
            .addCase(getMachines.fulfilled, (state, action) => {
                state.MachinesIsLoading = false;
                state.machines = action.payload.data;
            })
            .addCase(getMachines.rejected, (state, action) => {
                state.MachinesIsLoading = false;
                state.MachinesError = action.payload;
            })
            .addCase(getOwnedMachines.pending, (state) => {
                state.ownedMachinesIsLoading = true;
            })
            .addCase(getOwnedMachines.fulfilled, (state, action) => {
                state.ownedMachinesIsLoading = false;
                state.ownedMachines = action.payload.data;
            })
            .addCase(getOwnedMachines.rejected, (state, action) => {
                state.ownedMachinesIsLoading = false;
                state.ownedMachinesError = action.payload;
            });
    },
});

export default machinesSlice.reducer;
