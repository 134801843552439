import { useEffect } from "react";
import {
    getUser,
    getUserDownlines,
    inviteErnings,
    updateUser,
} from "../redux/UserActions";
import { RootState, store } from "../../../app/store";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { toastOption } from "../../../constants";
import { useNavigate } from "react-router-dom";

export const useGetUser = () => {
    const { user, getUserIsLoading, getUserError } = useSelector(
        (state: RootState) => state.user
    );
    useEffect(() => {
        store.dispatch(getUser({ empty: "" }));
    }, []);

    return {
        user,
        getUserIsLoading,
        getUserError,
    };
};

export const useUpdateUser = () => {
    const navigate = useNavigate();
    const { user, updateUserIsLoading, updateUserError } = useSelector(
        (state: RootState) => state.user
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            full_name: user?.full_name,
            email: user?.email,
            phone_number: user?.phone_number,
        },
        validationSchema: Yup.object({
            full_name: Yup.string().required("This field cannot be empty"),
            email: Yup.string().required("This field cannot be empty"),
            phone_number: Yup.string().required("This field cannot be empty"),
        }),
        onSubmit: (values) => {
            store
                .dispatch(updateUser(values))
                .unwrap()
                .then(() => {
                    toast.success("User updated successfully", toastOption);
                    navigate("/");
                })
                .catch(() => {
                    toast.error("Error updating user", toastOption);
                });
        },
    });

    return {
        user,
        updateUserIsLoading,
        updateUserError,
        formik,
    };
};

export const useUserDownlines = () => {
    const { userDownlinesIsLoading, userDownlines, user, earning, claimInviteErningsIsLoading } =
        useSelector((state: RootState) => state.user);

    useEffect(() => {
        store.dispatch(
            getUserDownlines({ referral_code: user.referral_code! })
        );
    }, [user]);

    useEffect(() => {
        store.dispatch(inviteErnings({ user: user?.id! }));
    }, [user.id]);

    return {
        userDownlinesIsLoading,
        userDownlines,
        user,
        earning,
        claimInviteErningsIsLoading,
        store
    };
};
