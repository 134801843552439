import React, { useState, useEffect } from "react";
import { differenceInSeconds, parseISO } from "date-fns";
import { CountUpTimerContainer } from "./CountUpTimer.styled";

interface TimeElapsed {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const CountUpTimer: React.FC = () => {
  const [timeElapsed, setTimeElapsed] = useState<TimeElapsed>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate = parseISO("2024-02-01T00:00:00");

    const calculateTimeElapsed = () => {
      const timeDifference = differenceInSeconds(new Date(), targetDate);
      const days = Math.floor(timeDifference / (60 * 60 * 24));
      const hours = Math.floor((timeDifference % (60 * 60 * 24)) / (60 * 60));
      const minutes = Math.floor((timeDifference % (60 * 60)) / 60);
      const seconds = timeDifference % 60;

      setTimeElapsed({
        days,
        hours,
        minutes,
        seconds,
      });
    };

    const intervalId = setInterval(() => {
      calculateTimeElapsed();
    }, 1000);

    calculateTimeElapsed(); // Initial calculation

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <CountUpTimerContainer>
      <h3>SERVER RUNTIME</h3>
      <div>
        <div>
          <h1>{timeElapsed.days}</h1>
          <span>Days</span>
        </div>
        <div>
          <h1>{timeElapsed.hours}</h1>
          <span>Hours</span>
        </div>
        <div>
          <h1>{timeElapsed.minutes}</h1>
          <span>Minutes</span>
        </div>
        <div>
          <h1>{timeElapsed.seconds}</h1>
          <span>Seconds</span>
        </div>
      </div>
    </CountUpTimerContainer>
  );
};

export default CountUpTimer;
