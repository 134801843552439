import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const endPoint = process.env.REACT_APP_API_URL;

export const getMachines = createAsyncThunk(
    "getMachines",
    async ({ empty }: { empty: string }, thunkApi) => {
        try {
            const config = {
                headers: {
                    // 'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const res = await axios.get(`${endPoint}/machines/?available=true`, config);
            return thunkApi.fulfillWithValue(res);
        } catch (error: any) {
            console.log(error);
            return thunkApi.rejectWithValue(error.response.data);
        }
    }
);

export const buyMachine = createAsyncThunk(
    "buyMachine",
    async (
        {
            user,
            machine,
            quantity,
        }: { user?: string | number; machine: string; quantity: number },
        thunkApi
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const res = await axios.post(
                `${endPoint}/equipements/`,
                { user, machine, quantity, order_type: "buy" },
                config
            );
            return thunkApi.fulfillWithValue(res);
        } catch (error: any) {
            return thunkApi.rejectWithValue(await error.response.data);
        }
    }
);

export const getOwnedMachines = createAsyncThunk(
    "getOwnedMachines",
    async ({ user_id }: { user_id: string | number }, thunkApi) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const res = await axios.get(
                `${endPoint}/equipements/?user=${user_id}`,
                config
            );
            return thunkApi.fulfillWithValue(res);
        } catch (error: any) {
            return thunkApi.rejectWithValue(error.response.data);
        }
    }
);

export const activateMachine = createAsyncThunk(
    "activateMachine",
    async ({ user, data }: { user: string; data: any }, thunkApi) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const res = await axios.post(
                `${endPoint}/re_activate_machine/`,
                data,
                config
            );
            return thunkApi.fulfillWithValue(res);
        } catch (error: any) {
            return thunkApi.rejectWithValue(await error.response.data);
        }
    }
);
