import { useSelector } from "react-redux";
import { RootState, store } from "../../app/store";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MIN_AMOUNT, toastOption } from "../../constants";
import { withdraw } from "./redux/WithdrawActions";
import { useEffect } from "react";
import { getUserBalance } from "../wallet/redux/WalletActions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const useWithdraw = () => {
    const navigate = useNavigate();

    const { user } = useSelector((state: RootState) => state.user);
    const { balance } = useSelector((state: RootState) => state.wallet);
    const { withdrawIsLoading, withdrawError } = useSelector(
        (state: RootState) => state.withdraw
    );

    useEffect(() => {
        store.dispatch(getUserBalance({}));
    }, []);

    const handlePaste = () => {
        const clipboardData = navigator.clipboard;
        clipboardData.readText().then((pastedText) => {
            formik.setFieldValue("withdrawal_address", pastedText);
        });
    };

    const formik = useFormik({
        initialValues: {
            user: user.id,
            withdrawal_address: "",
            amount: "",
            pin: "",
        },
        onSubmit: (values) => {
            store
                .dispatch(withdraw(values))
                .unwrap()
                .then((res) => {
                    navigate("/wallet");
                    toast.success("Withdraw successful.", toastOption);
                })
                .catch((error) => {
                    toast.error(`${error}`, toastOption);
                });
        },
        validationSchema: Yup.object({
            withdrawal_address: Yup.string()
                .matches(/^T[1-9A-HJ-NP-Za-km-z]{33}$/, "Invalid Tron address")
                .required("This field cannot be empty"),
            amount: Yup.number()
                .min(MIN_AMOUNT, `minimum withdrawal is ${MIN_AMOUNT} TRX`)
                .max(balance.balance, `Your balance is ${balance.balance} TRX`)
                .required("This field cannot be empty"),
            pin: Yup.string()
                .min(4, "Pin must be 4 digit")
                .max(4, "Pin must be 4 digit")
                .required("This field cannot be empty"),
        }),
    });

    return {
        formik,
        withdrawIsLoading,
        withdrawError,
        handlePaste,
    };
};
