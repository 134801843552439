import styled from "styled-components";

export const CountUpTimerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: ${({ theme }) => `thin solid ${theme.colors.primary}`};
    height: 150px;
    margin-block: 50px 80px;

    & > div {
        display: flex;
        color: ${({ theme }) => theme.colors.primary};

        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px;
            border-right: ${({ theme }) =>
                `thin solid ${theme.colors.primary}`};
            border-image: linear-gradient(
                    to bottom,
                    ${({ theme }) => theme.colors.primary},
                    ${({ theme }) => theme.colors.background}
                )
                1;
            &:last-child {
                border: none;
            }

            & > h1 {
                font-weight: bold;
                font-size: 35px;
                line-height: 0;
            }

            & > span {
                font-size: 15px;
                margin-top: 5px;
            }
        }
    }
`;
