import React from "react";
import CountUpTimer from "../../components/CountUpTimer/CountUpTimer";
import Streak from "../../components/Streak/Streak";
import { HomeContainer } from "./Home.styled";
import HomeGridLink from "../../components/HomeGridLink/HomeGridLink";
import { useNotifications } from "../notifications/hooks/useNotifications";
import { useGetUser } from "../../components/User/hooks/useUser";

const Home = () => {
    useNotifications();
    useGetUser();

    return (
        <HomeContainer className="page-content">
            <CountUpTimer />
            <Streak />
            <HomeGridLink />
        </HomeContainer>
    );
};

export default Home;
