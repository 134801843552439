import React from "react";
import { OwnedMachineInterface } from "../../pages/machines/redux/MachineSlice";
import styled from "styled-components";
import Button from "../Button";
import { ButtonVariant } from "../Button/ButoonInteface";

interface OwnedMachineItemProps {
    owned_machine: OwnedMachineInterface;
}

const OwnedMachineItemStyle = styled.div`
    background-color: ${({ theme }) => theme.colors.secondary}50;

    & > img {
        width: 100%;
        height: 100px;
        object-fit: cover;
    }

    & > .headline,
    & > .sub-headline {
        padding-inline: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > .title {
            color: ${({ theme }) => theme.colors.primary};
        }
    }

    & > button {
        height: 30px;
        margin-inline-start: 10px;
    }
`;

const OwnedMachineItem = ({ owned_machine }: OwnedMachineItemProps) => {
    return (
        <OwnedMachineItemStyle>
            <img
                src={owned_machine.machine_set.image}
                alt={owned_machine.machine_set.name}
            />
            <div className="headline">
                <div className="title">{owned_machine.machine_set.name}</div>{" "}
                <div>{owned_machine.machine_set.price}TRX</div>
            </div>
            <div className="sub-headline">
                <div>Daily income</div>
                <div>{owned_machine.machine_set.daily_profit}TRX</div>
            </div>
            <div className="sub-headline">
                <div>Own</div>
                <div>{owned_machine.quantity}</div>
            </div>
            <div className="sub-headline">
                <div>Status</div>
                <div>{owned_machine.is_activated ? "Active" : "Expired"}</div>
            </div>
            {!owned_machine.is_activated && (
                <Button variant={ButtonVariant.Primary}>Re-Activate</Button>
            )}
        </OwnedMachineItemStyle>
    );
};

export default OwnedMachineItem;
