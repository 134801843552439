import React from "react";
import { MachineInterface } from "../../pages/machines/redux/MachineSlice";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const MachineItemStyle = styled.div`
    background-color: ${({ theme }) => theme.colors.secondary}50;
    cursor: pointer;

    & > .owned-tag {
        position: absolute;
        padding: 2px;
        background-color: ${({ theme }) => theme.colors.primary};
    }

    & > img {
        width: 100%;
        height: 100px;
        object-fit: cover;
    }

    & > .headline,
    & > .sub-headline {
        padding-inline: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > .title {
            color: ${({ theme }) => theme.colors.primary};
        }
    }
`;

interface MachineItemProps {
    machine: MachineInterface;
    owned: boolean;
}

const MachineItem = ({ machine, owned }: MachineItemProps) => {
    const navigate = useNavigate();
    return (
        <MachineItemStyle
            onClick={() =>
                navigate("/buy-machine", { state: { machine: machine } })
            }
        >
            {owned && <div className="owned-tag">Owned</div>}
            <img src={machine.image} alt={machine.name} />
            <div className="headline">
                <div className="title">{machine.name}</div>{" "}
                <div>{machine.price}TRX</div>
            </div>
            <div className="sub-headline">
                <div>Daily income</div>
                <div>{machine.daily_profit}TRX</div>
            </div>
            <div className="sub-headline">
                <div>Max buy</div>
                <div>{machine.max_quantity}</div>
            </div>
        </MachineItemStyle>
    );
};

export default MachineItem;
