import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { RootState } from "../../app/store"


export const useBuyMachine = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const {user} = useSelector((state:RootState) => state.user)

    useEffect(() => {
        if(location.state === null) {
            navigate("machines")
        }
    }, [location.state, navigate])

    return {
        state: location?.state,
        user,
        navigate
    }
}