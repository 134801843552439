import { createSlice } from "@reduxjs/toolkit";
import { getUserBalance } from "./WalletActions";

export interface WALLET_STATE {
    getUserBalanceIsLoading: boolean;
    balance: any;
    getUserBalanceError?: string | null | unknown;
}

const initialState: WALLET_STATE = {
    getUserBalanceIsLoading: false,
    balance: 0, // for wallet array
    getUserBalanceError: null,
};

const walletSlice = createSlice({
    name: "wallet",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserBalance.pending, (state) => {
                state.getUserBalanceIsLoading = true;
                state.getUserBalanceError = null;
            })
            .addCase(getUserBalance.fulfilled, (state, action) => {
                state.getUserBalanceIsLoading = false;
                state.getUserBalanceError = null;
                state.balance = action.payload;
            })
            .addCase(getUserBalance.rejected, (state, { payload }) => {
                state.getUserBalanceIsLoading = false;
                state.getUserBalanceError = payload;
            });
    },
});

export default walletSlice.reducer;
