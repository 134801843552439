import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

var firebaseConfig = {
    apiKey: "AIzaSyBKtnIe3uEHTgXC0Tq91_b_VF52RzPIteA",
    authDomain: "tronstack-b91a2.firebaseapp.com",
    projectId: "tronstack-b91a2",
    storageBucket: "tronstack-b91a2.appspot.com",
    messagingSenderId: "405865236917",
    appId: "1:405865236917:web:0042c3dc411b74364171fc",
    measurementId: "G-C8D1LE64G6",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getToken_ = async (setTokenFound) => {
    return getToken(messaging)
        .then((currentToken) => {
            if (currentToken) {
                console.log("current token for client: ", currentToken);
                setTokenFound(true);
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            } else {
                console.log(
                    "No registration token available. Request permission to generate one."
                );
                setTokenFound(false);
                // shows on the UI that permission is required
            }
        })
        .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
            // catch error while creating client token
        });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});