import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../pages/login/redux/loginSlice";
import registerReducer from "../pages/register/redux/registerSlice";
import userReducer from "../components/User/redux/UserSlice";
import notificationsReducer from "../pages/notifications/redux/notificationsSlice";
import walletReducer from "../pages/wallet/redux/WalletSlice";
import depositReducer from "../pages/deposit/redux/DepositSlice";
import historyReducer from "../pages/history/redux/HistorySlice";
import changePasswordReducer from "../pages/change-password/redux/changePasswordSlice";
import withdrawalPinReducer from "../pages/set-withdrawal-pin/redux/withdrawalPinSlice";
import withdrawReducer from "../pages/withdraw/redux/withdrawSlice";
import MachineReducer from "../pages/machines/redux/MachineSlice";

export const store = configureStore({
    reducer: {
        login: loginReducer,
        register: registerReducer,
        user: userReducer,
        notification: notificationsReducer,
        wallet: walletReducer,
        deposit: depositReducer,
        history: historyReducer,
        changePassword: changePasswordReducer,
        withdrawalPin: withdrawalPinReducer,
        withdraw: withdrawReducer,
        machine: MachineReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
