import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const endPoint = process.env.REACT_APP_API_URL;

export const withdraw = createAsyncThunk(
    "withdraw",
    async (
        {
            user,
            amount,
            withdrawal_address,
            pin,
        }: {
            user?: string | number;
            amount: string;
            withdrawal_address: string;
            pin: string;
        },
        thunkApi
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const res = await axios.post(
                `${endPoint}/withdraws/`,
                { user, amount, address_used: withdrawal_address, pin },
                config
            );
            return thunkApi.fulfillWithValue(res);
        } catch (error: any) {
            return thunkApi.rejectWithValue(await error.response.data);
        }
    }
);
