import React from "react";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { ButtonVariant } from "../../components/Button/ButoonInteface";
import CustomLoading from "../../components/CustomLoading/CustomLoading";
import { useGetUser, useUpdateUser } from "../../components/User/hooks/useUser";
import PageLoading from "../../components/PageLoading/PageLoading";

const Profile = () => {
    const { getUserIsLoading } = useGetUser();
    const { formik, updateUserIsLoading, updateUserError } = useUpdateUser();

    if (getUserIsLoading) return <PageLoading transparentBackground={true} />;

    return (
        <div className="page-content">
            <h1>Update Account</h1>
            <form
                onSubmit={formik.handleSubmit}
                style={{ display: "flex", flexDirection: "column" }}
            >
                <div className="form-group">
                    <label htmlFor="full_name">Full name</label>
                    <Input
                        type="text"
                        id="full_name"
                        placeholder="Full name"
                        name="full_name"
                        value={formik.values.full_name}
                        onChange={formik.handleChange}
                        error={
                            formik.errors?.full_name ||
                            updateUserError?.full_name
                                ? true
                                : false
                        }
                    />
                    <div className="form-error">
                        {formik.errors?.full_name || updateUserError?.full_name}
                    </div>
                    <small id="nameHelp" className="form-text text-muted">
                        We'll never share your name with anyone else.
                    </small>
                </div>
                <div className="form-group">
                    <label htmlFor="email">Your email</label>
                    <Input
                        type="email"
                        id="email"
                        placeholder="Email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                            formik.errors?.email || updateUserError?.email
                                ? true
                                : false
                        }
                        disabled
                    />
                    <div className="form-error">
                        {formik.errors?.email || updateUserError?.email}
                    </div>
                    <small id="nameHelp" className="form-text text-muted">
                        We'll never share your email with anyone else.
                    </small>
                </div>
                <div className="form-group">
                    <label htmlFor="phone_number">Phone number</label>
                    <Input
                        type="tel"
                        id="phone_number"
                        placeholder="Phone number"
                        name="phone_number"
                        value={formik.values.phone_number}
                        onChange={formik.handleChange}
                        error={
                            formik.errors?.phone_number ||
                            updateUserError?.phone_number
                                ? true
                                : false
                        }
                    />
                    <div className="form-error">
                        {formik.errors?.phone_number ||
                            updateUserError?.phone_number}
                    </div>
                    <small id="nameHelp" className="form-text text-muted">
                        We'll never share your phone number with anyone else.
                    </small>
                </div>

                <Button
                    type="submit"
                    variant={ButtonVariant.Primary}
                    disabled={updateUserIsLoading}
                >
                    {updateUserIsLoading ? <CustomLoading /> : "Update"}
                </Button>
            </form>
        </div>
    );
};

export default Profile;
