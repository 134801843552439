import React from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../common/actions/logoutAction";
import styled from "styled-components";
import { MdKeyboardArrowRight } from "react-icons/md";
import { THEME_INTERFACE } from "../../themes/ThemeInterface";
import SettingsLevel from "../../components/SettingsLevel/SettingsLevel";
import { store } from "../../app/store";

const SettingsLinkSectionContainer = styled.div`
    margin-bottom: 10px;
`;
const SettingsLinkStyle = styled.div<{ isVersion?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2.5px -24px;
    padding: 15px 24px;
    cursor: pointer;
    background-color: ${(props) => props.theme.inputs.backgroundColor};

    & > div:last-child {
        color: ${({ theme, isVersion }) => isVersion && theme.colors.primary};
    }
`;

const LogoutSettingsLinkStyle = styled(SettingsLinkStyle)`
    color: ${({ theme }) => theme.colors.error};
`;

const Settings = () => {
    const navigate = useNavigate();
    const settingLinks = {
        section1: [
            {
                title: "Account Information",
                link: "/profile",
            },
            {
                title: "Link Accounts",
                link: "/link-accounts",
            },
        ],
        section2: [
            {
                title: "Change Password",
                link: "/change-password",
            },
            {
                title: "Set Pin",
                link: "/set-withdrawal-pin",
            },
        ],
        section3: [
            {
                title: "About TronStack",
                link: "/about",
            },
            {
                title: "User Guides",
                link: "/user-guides",
            },
            {
                title: "Support",
                link: "/support",
            },
            {
                title: "Version",
                link: "",
            },
        ],
        section4: [{ title: "Logout" }],
    };

    return (
        <div className="page-content">
            <SettingsLinkSectionContainer>
                <SettingsLevel />
            </SettingsLinkSectionContainer>
            <SettingsLinkSectionContainer>
                {settingLinks.section1.map((link, index) => (
                    <SettingsLinkStyle
                        key={index}
                        onClick={() => {
                            navigate(link.link);
                        }}
                    >
                        <div>{link.title}</div>
                        <MdKeyboardArrowRight />
                    </SettingsLinkStyle>
                ))}
            </SettingsLinkSectionContainer>
            <SettingsLinkSectionContainer>
                {settingLinks.section2.map((link, index) => (
                    <SettingsLinkStyle
                        key={index}
                        onClick={() => {
                            navigate(link.link);
                        }}
                    >
                        <div>{link.title}</div>
                        <MdKeyboardArrowRight />
                    </SettingsLinkStyle>
                ))}
            </SettingsLinkSectionContainer>
            <SettingsLinkSectionContainer>
                {settingLinks.section3.map((link, index) => (
                    <>
                        {index + 1 === settingLinks.section3.length ? (
                            <SettingsLinkStyle key={index} isVersion={true}>
                                <div>{link.title}</div>
                                <div
                                    style={{
                                        color: `${({
                                            defaultTheme,
                                        }: THEME_INTERFACE) =>
                                            defaultTheme.colors.primary}`,
                                    }}
                                >
                                    0.1.3
                                </div>
                            </SettingsLinkStyle>
                        ) : (
                            <SettingsLinkStyle
                                isVersion={false}
                                key={index}
                                onClick={() => {
                                    navigate(link.link);
                                }}
                            >
                                <div>{link.title}</div>
                                <MdKeyboardArrowRight />
                            </SettingsLinkStyle>
                        )}
                    </>
                ))}
            </SettingsLinkSectionContainer>
            <SettingsLinkSectionContainer>
                {settingLinks.section4.map((link, index) => (
                    <LogoutSettingsLinkStyle
                        key={index}
                        onClick={() => {
                            store
                                .dispatch(logout({ empty: "" }))
                                .unwrap()
                                .then(() => navigate("/login"));
                        }}
                    >
                        <div>{link.title}</div>
                        <MdKeyboardArrowRight />
                    </LogoutSettingsLinkStyle>
                ))}
            </SettingsLinkSectionContainer>
        </div>
    );
};

export default Settings;
