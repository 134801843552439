import { createSlice } from "@reduxjs/toolkit";
import { getUserHistories } from "./HistoryActions";

export interface USER_HISTORY {
    id: string;
    subject: string;
    message: string;
    tx_type: string;
    amount: string;
    date: string;
    user: string;
}

export interface HISTORY_STATE {
    userHistoryIsLoading: boolean;
    userHistories: USER_HISTORY[];
    userHistoryError?: string | null | unknown;
}

const initialState: HISTORY_STATE = {
    userHistoryIsLoading: false,
    userHistories: [],
    userHistoryError: null,
};

const HistorySlice = createSlice({
    name: "HistorySlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserHistories.pending, (state) => {
                state.userHistoryIsLoading = true;
            })
            .addCase(getUserHistories.fulfilled, (state, action) => {
                state.userHistoryIsLoading = false;
                state.userHistories = action.payload.data;
            })
            .addCase(getUserHistories.rejected, (state, action) => {
                state.userHistoryIsLoading = false;
                state.userHistoryError = action.payload;
            });
    },
});

export default HistorySlice.reducer;
