import { createSlice } from "@reduxjs/toolkit";
import {
    getUserNotifications,
    notificationMarkAllAsRead,
} from "./notificationsAction";
import { NOTIFICATIONS_INTERFACE } from "../interface/notifications";

const initialState: NOTIFICATIONS_INTERFACE = {
    markNotificationAsReadLoading: false,
    getUserNotificationsLoading: false,
    notificationMarkAllAsReadLoading: false,
    userNotifications: [],
    markNotificationAsReadError: null,
    getUserNotificationsError: null,
};

const NotificaionSlice = createSlice({
    name: "NotificaionSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserNotifications.pending, (state) => {
                state.getUserNotificationsLoading = true;
            })
            .addCase(getUserNotifications.fulfilled, (state, action) => {
                state.getUserNotificationsLoading = false;
                state.userNotifications = action.payload;
            })
            .addCase(getUserNotifications.rejected, (state, action) => {
                state.getUserNotificationsLoading = false;
                // state.getUserNotificationsError = action.payload;
            })
            .addCase(notificationMarkAllAsRead.pending, (state) => {
                state.notificationMarkAllAsReadLoading = true;
            })
            .addCase(notificationMarkAllAsRead.fulfilled, (state, action) => {
                state.notificationMarkAllAsReadLoading = false;
                state.userNotifications = action.payload;
            })
            .addCase(notificationMarkAllAsRead.rejected, (state, action) => {
                state.notificationMarkAllAsReadLoading = false;
                // state.markNotificationAsReadError = action.payload;
            })
    },
});

export default NotificaionSlice.reducer;
