import styled from "styled-components";

export const StreakContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-inline: 30px;
    border: thin solid;
    border-image: linear-gradient(
            to bottom,
            ${({ theme }) => theme.colors.primary},
            ${({ theme }) => theme.colors.background}
        )
        1;
    border-bottom: 0px;
    margin-bottom: 10px;

    & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${({ theme }) => theme.colors.primary};
    }
`;
