import { createSlice } from "@reduxjs/toolkit";
import { setWithdrawalPin, getWithdrawalPin } from "./withdrawalPinActions";

export interface SET_WITHDRAW_PIN_ACTIONS {
    pin: string;
    setWithdrawalPinIsLoading: boolean;
    setWithdrawalPinError?: string | null | unknown;
    getWithdrawalPinIsLoading: boolean;
    getWithdrawalPinError?: string | null | unknown;
}

const initialState: SET_WITHDRAW_PIN_ACTIONS = {
    pin: "",
    setWithdrawalPinIsLoading: false,
    setWithdrawalPinError: null,
    getWithdrawalPinIsLoading: false,
    getWithdrawalPinError: null,
};

const withdrawalPinSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(setWithdrawalPin.pending, (state) => {
                state.setWithdrawalPinIsLoading = true;
                state.setWithdrawalPinError = null;
            })
            .addCase(setWithdrawalPin.fulfilled, (state, action) => {
                state.setWithdrawalPinIsLoading = false;
                state.setWithdrawalPinError = null;
            })
            .addCase(setWithdrawalPin.rejected, (state, action) => {
                state.setWithdrawalPinIsLoading = false;
                state.setWithdrawalPinError = action.payload;
            })
            .addCase(getWithdrawalPin.pending, (state) => {
                state.getWithdrawalPinIsLoading = true;
                state.getWithdrawalPinError = null;
            })
            .addCase(getWithdrawalPin.fulfilled, (state, action) => {
                state.getWithdrawalPinIsLoading = false;
                state.getWithdrawalPinError = null;
            })
            .addCase(getWithdrawalPin.rejected, (state, action) => {
                state.getWithdrawalPinIsLoading = false;
                state.getWithdrawalPinError = action.payload;
            });
    },
});

export default withdrawalPinSlice.reducer;
