import { RootState, store } from "../../app/store";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { toastOption } from "../../constants";
import { useSelector } from "react-redux";
import { setWithdrawalPin } from "./redux/withdrawalPinActions";

export const useWithdrawalPin = () => {
    const { user } = useSelector((state: RootState) => state.user);
    const { setWithdrawalPinIsLoading, setWithdrawalPinError } = useSelector(
        (state: RootState) => state.withdrawalPin
    );
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            user: user.id,
            pin: "",
            password: "",
        },
        onSubmit: (values) => {
            store
                .dispatch(setWithdrawalPin(values))
                .unwrap()
                .then((res) => {
                    toast.success("Pin set successfully", toastOption);
                })
                .catch((error) => console.log(error));
        },
        validationSchema: Yup.object({
            pin: Yup.string()
                .min(4, "Pin must be 4 digit")
                .max(4, "Pin must be 4 digit")
                .required("This field cannot be empty"),
            password: Yup.string().required("This field cannot be empty"),
        }),
    });

    return {
        formik,
        setWithdrawalPinIsLoading,
        setWithdrawalPinError,
    };
};
