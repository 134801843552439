import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { UserObject } from "../../../components/User/redux/interface";

const endPoint = process.env.REACT_APP_API_URL;

export const markNotificationAsRead = createAsyncThunk(
    "notification/markNotificationAsRead",
    async ({ id }: { id: string }, thunkApi) => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const res = await axios.patch(
                `${endPoint}/notifications/${id}/`,
                {
                    is_read: true,
                },
                config
            );
            const user: UserObject = res.data.user;
            const response = await axios.get(
                `${endPoint}/notifications/?user=${user.id}`,
                config
            );
            return thunkApi.fulfillWithValue(response.data);
        } catch (error: any) {
            return thunkApi.rejectWithValue(await error.response.data);
        }
    }
);


