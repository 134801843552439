import { THEME_INTERFACE } from "./ThemeInterface";

const themes: THEME_INTERFACE = {
    defaultTheme: {
        id: "T_003",
        name: "Default Theme",
        colors: {
            primary: "#00b894",
            secondary: "#2c3e50",
            background: "#121212",
            text: "#ecf0f1",
            accent: "#27ae60",
            success: "#2ecc71",
            warning: "#f39c12",
            error: "#e74c3c",
            info: "#3498db",
        },
        font: {
            family: "Poppins, sans-serif",
            size: {
                small: 12,
                medium: 16,
                large: 20,
            },
            heading: {
                family: "Poppins, sans-serif",
                weight: "bold",
            },
        },
        spacing: {
            margin: {
                small: 8,
                medium: 16,
                large: 24,
            },
            padding: {
                small: 8,
                medium: 16,
                large: 24,
            },
        },
        shadows: {
            small: "0 2px 4px rgba(0, 0, 0, 0.1)",
            medium: "0 4px 8px rgba(0, 0, 0, 0.2)",
            large: "0 8px 16px rgba(0, 0, 0, 0.3)",
        },
        buttons: {
            marginBlock: 10,
            height: 40,

            primary: {
                color: "white",
                border: "none",
                backgroundColor: "#00b894",
                hover: {
                    border: "thin solid #2c3e50",
                    backgroundColor: "#2c3e50",
                },
                disabled: {
                    border: "thin solid #2c3e50",
                    backgroundColor: "#2c3e50",
                },
            },
            secondary: {
                color: "#00b894",
                border: "thin solid #00b894",
                backgroundColor: "transparent",
                hover: {
                    color: "#2c3e50",
                    border: "thin solid #2c3e50",
                    backgroundColor: "transparent",
                },
                disabled: {
                    color: "#2c3e50",
                    border: "thin solid #C597F8",
                    backgroundColor: "transparent",
                },
            },
        },
        inputs: {
            border: "thin solid transparent",
            borderRadius: 0,
            height: 40,
            padding: 10,
            fontSize: 12,
            backgroundColor: "#16161E",
            color: "#fff",
            focus: {
                border: "thin solid #00b894",
            },
        },
        link: {
            primary: {
                color: "#00b894",
                hover: {
                    color: "#2c3e50",
                },
                disabled: {
                    color: "#2c3e50",
                },
            },
        },
        layout: {
            maxWidth: 500,
        },
    },
};

export default themes;
