import { toast } from "react-toastify";
import { toastOption } from "../constants";

const copyToClipboard = async (text: string, message: string) => {
    try {
        if (navigator.clipboard) {
            await navigator.clipboard.writeText(text);
            toast.success(message, toastOption);
        } else {
            const textarea = document.createElement("textarea");
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand("copy");
            document.body.removeChild(textarea);
            toast.success(message, toastOption);
        }
    } catch (error) {
        console.error("Error copying text: ", error);
    }
};

export default copyToClipboard;
