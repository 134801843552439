import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const endPoint = process.env.REACT_APP_API_URL;

export const setWithdrawalPin = createAsyncThunk(
    "setWithdrawalPin",
    async (
        {
            user,
            pin,
            password,
        }: { user?: string | number; pin: string; password: string },
        thunkApi
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const res = await axios.post(
                `${endPoint}/withdraw-pins/`,
                { user, pin, password },
                config
            );
            return thunkApi.fulfillWithValue(res);
        } catch (error: any) {
            return thunkApi.rejectWithValue(await error.response.data);
        }
    }
);

export const getWithdrawalPin = createAsyncThunk(
    "getWithdrawalPin",
    async ({ empty }: { empty: string }, thunkApi) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const res = await axios.get(
                `${endPoint}/withdraw-pins/null/`,
                config
            );
            console.log(res);
            return res;
        } catch (error: any) {
            return thunkApi.rejectWithValue(await error.response.data);
        }
    }
);
