import React from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Input from "../../components/Input/Input";
import { useLogin } from "./hooks/useLogin";
import { ButtonVariant } from "../../components/Button/ButoonInteface";
import CustomLoading from "../../components/CustomLoading/CustomLoading";

const Login = () => {
    const { formik, loginIsLoading } = useLogin();
    return (
        <div className="container">
            <h1 style={{ marginBlock: "50px" }}>Welcome Back!</h1>
            <form
                onSubmit={formik.handleSubmit}
                style={{ display: "flex", flexDirection: "column" }}
            >
                <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <Input
                        type="text"
                        id="username"
                        placeholder="Username"
                        name="username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        error={formik.errors?.username ? true : false}
                    />
                    <div className="form-error">{formik.errors?.username}</div>
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Input
                        type="password"
                        id="password"
                        placeholder="Password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.errors?.password ? true : false}
                    />
                    <div className="form-error">{formik.errors?.password}</div>
                </div>
                <Button
                    type="submit"
                    variant={ButtonVariant.Primary}
                    disabled={loginIsLoading}
                >
                    {loginIsLoading ? <CustomLoading /> : "Login"}
                </Button>
            </form>
            <span style={{ margin: "auto" }}>
                Don't have an account? <Link to={"/register"}>Register</Link>
            </span>
        </div>
    );
};

export default Login;
