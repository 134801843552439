import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const FooterContainer = styled.footer`
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100vw - 40px);
    padding-block: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.background};
    padding-inline: 20px;

    @media only screen and (min-width: 501px) {
        width: 500px;
        margin-inline: auto;
        left: auto;
        right: auto;
    }
`;

export const StyledLink = styled(NavLink)<{ activeClassName: string }>`
    color: #fff;
    stroke: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 10px;
    -webkit-tap-highlight-color: transparent;

    &:hover {
        color: ${({ theme }) => theme.link.primary.color};
    }

    &.active {
        color: ${({ theme }) => theme.link.primary.color};
    }

    & > div {
        font-size: 14px;
    }
`;
