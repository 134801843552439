import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState, store } from "../../../app/store";
import { useEffect } from "react";
import { getUserBalance } from "../redux/WalletActions";

export interface WALLET_BALANCE_OBJECT {
    name: string;
    balance: number;
}

export const useWallet = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { balance, getUserBalanceIsLoading, getUserBalanceError } =
        useSelector((state: RootState) => state.wallet);
    const { user } = useSelector((state: RootState) => state.user);

    const balanceList: WALLET_BALANCE_OBJECT[] = [
        {
            name: "Main Balance",
            balance: balance.balance,
        },
        {
            name: "Bonus",
            balance: balance.bonus,
        },
    ];

    useEffect(() => {
        store.dispatch(getUserBalance({ user_id: user.id }));
    }, [user]);

    return {
        navigate,
        dispatch,
        balanceList,
        getUserBalanceIsLoading,
        getUserBalanceError,
    };
};
