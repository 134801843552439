import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useNotification = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state === null) {
            navigate("/notifications");
        }
    }, [location.state, navigate]);

    return {
        state: location?.state,
    };
};
