// features/user/userSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
    getUser,
    updateUser,
    getUserDownlines,
    inviteErnings,
    claimInviteErnings,
    getUsers,
} from "./UserActions";
import { UserState } from "./interface";

const initialState: UserState = {
    user: {},
    getUserIsLoading: false,
    getUserError: null,
    updateUserIsLoading: false,
    updateUserError: null,
    userDownlinesIsLoading: false,
    userDownlines: [],
    userDownlinesError: null,
    inviteErningsIsLoading: false,
    inviteErningsError: null,
    earning: {},
    claimInviteErningsIsLoading: false,
    claimInviteErningsError: null,
    getUsersIsLoading: false,
    users: [],
    getUsersError: null,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUser.pending, (state) => {
                state.getUserIsLoading = true;
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.getUserIsLoading = false;
                state.user = action.payload.data;
            })
            .addCase(getUser.rejected, (state, action) => {
                state.getUserIsLoading = false;
                state.getUserError = action.payload;
            })
            .addCase(getUsers.pending, (state) => {
                state.getUsersIsLoading = true;
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.getUsersIsLoading = false;
                state.users = action.payload.data;
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.getUsersIsLoading = false;
                state.getUsersError = action.payload;
            })
            .addCase(updateUser.pending, (state) => {
                state.updateUserIsLoading = true;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.updateUserIsLoading = false;
                state.user = action.payload.data;
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.updateUserIsLoading = false;
                state.updateUserError = action.payload;
            })
            .addCase(getUserDownlines.pending, (state) => {
                state.userDownlinesIsLoading = true;
            })
            .addCase(getUserDownlines.fulfilled, (state, action) => {
                state.userDownlinesIsLoading = false;
                state.userDownlines = action.payload.data;
            })
            .addCase(getUserDownlines.rejected, (state, action) => {
                state.userDownlinesIsLoading = false;
                state.userDownlinesError = action.payload;
            })
            .addCase(inviteErnings.pending, (state) => {
                state.inviteErningsIsLoading = true;
            })
            .addCase(inviteErnings.fulfilled, (state, action) => {
                state.inviteErningsIsLoading = false;
                state.earning = action.payload.data;
            })
            .addCase(inviteErnings.rejected, (state, action) => {
                state.inviteErningsIsLoading = false;
                state.inviteErningsError = action.payload;
            })
            .addCase(claimInviteErnings.pending, (state) => {
                state.claimInviteErningsIsLoading = true;
            })
            .addCase(claimInviteErnings.fulfilled, (state, action) => {
                state.claimInviteErningsIsLoading = false;
                state.earning = action.payload.data;
            })
            .addCase(claimInviteErnings.rejected, (state, action) => {
                state.claimInviteErningsIsLoading = false;
                state.claimInviteErningsError = action.payload;
            });
    },
});

export default userSlice.reducer;
