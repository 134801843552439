import React from "react";
import styled from "styled-components";
import { UserObject } from "../User/redux/interface";

const NodeItem = ({ user }: { user: UserObject }) => {
    return (
        <NodeItemStyle>
            <div>{user.username}</div>
            <div>{user.has_machine ? "Active" : ""}</div>
        </NodeItemStyle>
    );
};

const NodeItemStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-inline: -24px;
    padding: 10px 24px 5px;
    border-bottom: ${({ theme }) => `thin solid ${theme.colors.primary}`};
`;

export default NodeItem;
