import React from "react";
import {
    WALLET_BALANCE_OBJECT,
    useWallet,
} from "../../pages/wallet/hooks/useWallet";
import styled from "styled-components";
import CustomLoading from "../CustomLoading/CustomLoading";

export interface BalanceItemProps {
    balance: WALLET_BALANCE_OBJECT;
}

const BalanceItem = ({ balance }: BalanceItemProps) => {
    const { getUserBalanceIsLoading } = useWallet();
    return (
        <BalanceItemStyle>
            <h3>{balance.name}</h3>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                {getUserBalanceIsLoading ? (
                    <CustomLoading />
                ) : (
                    <h3>{balance.balance} TRX</h3>
                )}
            </div>
        </BalanceItemStyle>
    );
};

const BalanceItemStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: ${({ theme }) => `thin solid ${theme.colors.primary}`};
    cursor: pointer;
`;

export default BalanceItem;
