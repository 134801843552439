import React from "react";
import { useChangePassword } from "./useChangePassword";
import ChangePasswordForm from "./feature/ChangePasswordForm";

const ChangePassword = () => {
    const { formik, changePasswordIsLoading, changePasswordError } =
        useChangePassword();
    return (
        <div className="page-content">
            <ChangePasswordForm
                formik={formik}
                changePasswordIsLoading={changePasswordIsLoading}
                changePasswordError={changePasswordError}
            />
        </div>
    );
};

export default ChangePassword;
