import React from "react";
import { useDeposit } from "./hooks/useDeposit";
import { format } from "date-fns";
import QRCode from "react-qr-code";
import styled from "styled-components";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { ButtonVariant } from "../../components/Button/ButoonInteface";
import { FaCopy } from "react-icons/fa6";
import copyToClipboard from "../../utils/copyToClipboard";

const Deposit = () => {
    const { timeLeft, address_used } = useDeposit();

    return (
        <div className="page-content">
            <DepositStyle>
                <div className="timer-container">
                    <h2>{timeLeft > 0 && format(timeLeft, "mm:ss")}</h2>
                </div>
                <div className="qr-container">
                    <QRCode
                        value={address_used ? address_used : ""}
                        bgColor={"#F0F0F0"}
                        fgColor={"#000000"}
                        size={200}
                    />
                </div>
                <div className="address-container">
                    <Input value={`${address_used}`} error={false} disabled />
                    <Button
                        variant={ButtonVariant.Primary}
                        onClick={() =>
                            copyToClipboard(
                                address_used!,
                                "Address copied to clipboad."
                            )
                        }
                    >
                        <FaCopy />
                    </Button>
                </div>
                <div>
                    <h3 style={{ textAlign: "center" }}>Important:</h3>
                    <ul
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            padding: 0,
                            listStyle: "none",
                            textAlign: "center",
                        }}
                    >
                        <li>
                            <b>Deposit Deadline:</b> Complete your TRX (TRC-20)
                            deposit within the 1 hour timeframe.
                        </li>
                        <li>
                            <b>Network:</b> Use TRC-20 network only; do not send
                            from other networks.
                        </li>
                        <li>
                            <b>Confirmation Time:</b> Allow a few minutes for
                            processing; check the Tron blockchain explorer for
                            updates.
                        </li>
                    </ul>
                    <p>
                        <b>Note:</b> Late deposits may not be credited. Contact
                        support for assistance.
                    </p>
                </div>
            </DepositStyle>
        </div>
    );
};

const DepositStyle = styled.div`
    & > .timer-container {
        display: flex;
        justify-content: center;
        width: 30%;
        margin: 10px auto;
        color: ${({ theme }) => theme.colors.text};
        background-image: linear-gradient(
            ${(props) => props.theme.colors.secondary},
            ${(props) => props.theme.colors.background} 45%
        );
    }

    & > .qr-container {
        display: flex;
        justify-content: center;
        margin-block: 20px;
    }

    & > .address-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 100%;

        & > input {
            width: 80%;
            color: #ffffff !important;
        }
    }
`;

export default Deposit;
