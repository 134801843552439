import React from "react";
import { IoIosNotifications } from "react-icons/io";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { USERNOTIFICATION_OBJECT } from "../../pages/notification/interface/notification";
import { markNotificationAsRead } from "../../pages/notification/redux/notificationActions";
import { store } from "../../app/store";

const NotificationItemStyle = styled.div<{ isRead: boolean }>`
    display: flex;
    gap: 10px;
    margin-block: 15px;
    padding: 10px;
    cursor: pointer;
    background-color: ${({ theme, isRead }) =>
        isRead ? "" : theme.colors.secondary + "30"};

    & > div:first-child {
        padding: 8px 8px 4px 8px;
        height: fit-content;
        width: fit-content;
        background-color: ${({ theme, isRead }) =>
            isRead ? "white" : theme.colors.secondary};
    }
`;

const NotificationItem = ({
    notification,
}: {
    notification: USERNOTIFICATION_OBJECT;
    key: number;
}) => {
    const navigate = useNavigate();
    return (
        <NotificationItemStyle
            isRead={notification.is_read}
            onClick={() => {
                store.dispatch(markNotificationAsRead({ id: notification.id }));
                navigate(`/notification`, {
                    state: {
                        notification: notification,
                    },
                });
            }}
        >
            <div>
                <IoIosNotifications
                    size={20}
                    color={`${!notification.is_read ? "#00b894" : "grey"}`}
                />
            </div>
            <div>
                <h3
                    style={{
                        lineHeight: 0,
                        color: `${!notification.is_read && "#00b894"}`,
                    }}
                >
                    {notification.subject}
                </h3>
                <small style={{ opacity: 0.5 }}>{`${notification.message.slice(
                    0,
                    80
                )} ${notification.message.length > 80 ? "..." : ""}`}</small>
                <div>
                    <small style={{ opacity: 0.5 }}>
                        {format(notification.date, "HH:mm dd/MM/yyyy")}
                    </small>
                </div>
            </div>
        </NotificationItemStyle>
    );
};

export default NotificationItem;
