import React from "react";
import { useUserDownlines } from "../../components/User/hooks/useUser";
import PageLoading from "../../components/PageLoading/PageLoading";
import NodeItem from "../../components/NodeItem/NodeItem";
import EmptyList from "../../components/EmptyList/EmptyList";

const NetworkList = () => {
    const { userDownlinesIsLoading, userDownlines } = useUserDownlines();

    if (userDownlinesIsLoading) {
        return <PageLoading />;
    }

    if (!userDownlines.length) {
        return <EmptyList message={"No Downline"} />;
    }

    return (
        <div className="page-content">
            {userDownlines.map((user, index) => (
                <NodeItem key={index} user={user} />
            ))}
        </div>
    );
};

export default NetworkList;
