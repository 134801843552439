import React from "react";
import SetWithdrawalPinForm from "./feature/SetWithdrawalPinForm";
import { useWithdrawalPin } from "./useWithdrawalPin";

const SetWithdrawalPin = () => {
    const { formik, setWithdrawalPinIsLoading, setWithdrawalPinError } =
        useWithdrawalPin();
    return (
        <div className="page-content">
            <SetWithdrawalPinForm
                formik={formik}
                setWithdrawalPinIsLoading={setWithdrawalPinIsLoading}
                setWithdrawalPinError={setWithdrawalPinError}
            />
        </div>
    );
};

export default SetWithdrawalPin;
