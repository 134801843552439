import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserHistories } from "./redux/HistoryActions";
import { RootState, store } from "../../app/store";

export const useHistory = () => {
    const dispatch = useDispatch();
    const { userHistories, userHistoryIsLoading, userHistoryError } =
        useSelector((state: RootState) => state.history);
    const { user } = useSelector((state: RootState) => state.user);

    useEffect(() => {
        store.dispatch(getUserHistories({ user_id: user.id }));
    }, [dispatch, user]);

    return {
        userHistories,
        userHistoryIsLoading,
        userHistoryError,
    };
};
