import { RootState, store } from "../../app/store";
import { useFormik } from "formik";
import * as Yup from "yup";
import { changePassword } from "./redux/changePasswordActions";
import { logout } from "../../common/actions/logoutAction";
import { toast } from "react-toastify";
import { toastOption } from "../../constants";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useChangePassword = () => {
    const navigate = useNavigate();
    const { changePasswordIsLoading, changePasswordError } = useSelector(
        (state: RootState) => state.changePassword
    );
    const formik = useFormik({
        initialValues: {
            old_password: "",
            new_password1: "",
            new_password2: "",
        },
        onSubmit: (values) => {
            store
                .dispatch(changePassword(values))
                .unwrap()
                .then((res) => {
                    toast.success("Password changed successfully", toastOption);
                    store.dispatch(logout({ empty: "" }));
                    navigate("/login");
                })
                .catch((error) => console.log(error));
        },
        validationSchema: Yup.object({
            old_password: Yup.string().required("This field cannot be empty"),
            new_password1: Yup.string().required("This field cannot be empty"),
            new_password2: Yup.string()
                .required("This field cannot be empty")
                .oneOf([Yup.ref("new_password1")], "Password Must Match"),
        }),
    });

    return {
        formik,
        changePasswordIsLoading,
        changePasswordError,
    };
};
