import React from "react";
import NotificationItem from "../NotificationItem/NotificationItem";
import { USERNOTIFICATION_OBJECT } from "../../pages/notification/interface/notification";

const NotificationList = ({userNotifications}: {
    userNotifications: USERNOTIFICATION_OBJECT[];
}) => {
    return (
        <div>
            {userNotifications?.map((notification, index) => {
                return (
                    <NotificationItem key={index} notification={notification} />
                );
            })}
        </div>
    );
};

export default NotificationList;
