import React from "react";
import Button from "../Button";
import { ButtonVariant } from "../Button/ButoonInteface";
import styled from "styled-components";
import { useUserDownlines } from "../User/hooks/useUser";
import { claimInviteErnings } from "../User/redux/UserActions";
import CustomLoading from "../CustomLoading/CustomLoading";
import { toast } from "react-toastify";
import { toastOption } from "../../constants";
import { calculateUserLevelAndReward } from "../../utils/levelsAndRewards";

const SettingsLevelStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 2.5px -24px;
    padding: 15px 24px;
    gap: 10px;
    background-color: ${(props) => props.theme.inputs.backgroundColor};

    & > div > div > .level,
    & > div > .level {
        color: ${(props) => props.theme.colors.primary};
    }

    & > .meter {
        height: 10px;
        width: 100%;
        position: relative;
        background: #555;
        border-radius: 8px;
        padding: 0.1px;
    }

    & > .meter > span {
        display: block;
        height: 100%;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        background-color: ${(props) => props.theme.colors.primary};
        background-image: ${(props) =>
            `linear-gradient(center bottom,${props.theme.colors.primary} 69%, ${props.theme.colors.secondary} 37%)`};
        position: relative;
        overflow: hidden;
    }
`;

const SettingsLevel = () => {
    const {
        userDownlinesIsLoading,
        userDownlines,
        user,
        earning,
        claimInviteErningsIsLoading,
        store,
    } = useUserDownlines();
    const { full_name } = user;
    const numberOfUserDownlines = userDownlines.filter(
        (invite) => invite.has_machine
    ).length;

    const {
        userLevel,
        reward,
        friends_to_inv,
        can_claim,
        claimed,
        percentage,
    } = calculateUserLevelAndReward(numberOfUserDownlines, earning);

    return (
        <SettingsLevelStyle>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                        width: "50%",
                    }}
                >
                    <h3
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            lineHeight: 1,
                            marginBlock: 0,
                        }}
                    >
                        {full_name?.split(" ")[0]}
                    </h3>
                    <div className="level" style={{ width: "100%" }}>
                        Level {userLevel - 1}
                    </div>
                </div>
                <Button
                    variant={ButtonVariant.Primary}
                    disabled={!!claimed || (!claimed && !can_claim)}
                    onClick={() => {
                        store
                            .dispatch(
                                claimInviteErnings({
                                    user: user.id!,
                                    friends_to_inv: friends_to_inv!,
                                })
                            )
                            .unwrap()
                            .then(() => {
                                toast.success("Claim successful.", toastOption);
                            })
                            .catch((error) => {
                                console.log(error);
                                toast.error(`${error}`, toastOption);
                            });
                    }}
                >
                    {claimed ? (
                        "Claimed"
                    ) : claimInviteErningsIsLoading ? (
                        <CustomLoading />
                    ) : (
                        `Claim ${reward} TRX`
                    )}
                </Button>
            </div>
            <div className="meter">
                <span style={{ width: `${percentage}%` }}></span>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <div>
                    {userDownlinesIsLoading
                        ? ""
                        : `${
                              userDownlines.filter(
                                  (invite) => invite.has_machine
                              ).length
                          } / ${friends_to_inv} invites`}
                </div>
                <div className="level">Level {userLevel}</div>
            </div>
        </SettingsLevelStyle>
    );
};

export default SettingsLevel;
