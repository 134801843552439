import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const endPoint = process.env.REACT_APP_API_URL;

export const changePassword = createAsyncThunk(
    "auth/changePassword",
    async (
        {
            old_password,
            new_password1,
            new_password2,
        }: {
            old_password: string;
            new_password1: string;
            new_password2: string;
        },
        thunkApi
    ) => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const res = await axios.post(
                `${endPoint}/rest-auth/password/change/`,
                { old_password, new_password1, new_password2 },
                config
            );
            localStorage.setItem("token", res.data.access_token);
            localStorage.setItem("user", JSON.stringify(res.data.user));
            return res;
        } catch (error: any) {
            return thunkApi.rejectWithValue(await error.response.data);
        }
    }
);
