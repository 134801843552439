import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState, store } from "../../../app/store";
import { useEffect, useState } from "react";
import { getDeposit, updateDeposit } from "../redux/DepositActions";
import { toast } from "react-toastify";
import { EXPIRES_IN, toastOption } from "../../../constants";
import TronWeb from "tronweb";

export const useDeposit = () => {
    const param = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [timeLeft, setTimeLeft] = useState<number>(0);

    const { user } = useSelector((state: RootState) => state.user);
    const { deposit } = useSelector((state: RootState) => state.deposit);

    const address_used = deposit?.address_used;

    const NODE = process.env.REACT_APP_TRON_NODE;
    const EVENT_SERVER = process.env.REACT_APP_EVENT_SERVER;

    const handlePayment = async () => {
        const tronWeb = new TronWeb({
            fullNode: NODE,
            solidityNode: NODE,
            eventServer: EVENT_SERVER,
        });
        const balance = await tronWeb.trx.getBalance(address_used);
        if (balance > 0) {
            store
                .dispatch(
                    updateDeposit({
                        user: user.id,
                        id: param.id,
                    })
                )
                .unwrap()
                .then((res) => {
                    localStorage.removeItem("endTime");
                    toast.success(`Deposit successful.`, toastOption);
                    navigate("/wallet");
                })
                .catch((err) => console.log(err));
        }
    };

    const handleExpiredTimer = () => {
        localStorage.removeItem("endTime");
        localStorage.removeItem("depositId");
        navigate("/wallet");
    };

    useEffect(() => {
        if (param.id === null) {
            handleExpiredTimer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param.id]);

    useEffect(() => {
        const dateObj = new Date(deposit.date);
        const currentDate = new Date();
        const oneHourLater = new Date(currentDate.getTime() + EXPIRES_IN);

        if (
            dateObj > oneHourLater ||
            deposit.status === "SUCCESS" ||
            (Object.keys(user).length && deposit.user !== user.id)
        ) {
            handleExpiredTimer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deposit, navigate]);

    useEffect(() => {
        if (Object.keys(user).length) {
            try {
                store.dispatch(getDeposit({ id: param.id }));
            } catch (error) {
                console.error("Error fetching deposit:", error);
            }
        }
    }, [dispatch, param.id, user]);

    useEffect(() => {
        let storedEndTime: any = localStorage.getItem("endTime");
        if (localStorage.getItem("depositId")) {
            storedEndTime = localStorage.getItem("endTime") || setNewEndTime();
        }

        const timer = setInterval(async () => {
            const timeLeft = storedEndTime - Date.now();
            handlePayment();

            if (timeLeft <= 0) {
                clearInterval(timer);
                storedEndTime = setNewEndTime();
                setTimeLeft(0);

                handleExpiredTimer();

                if (storedEndTime < Date.now()) {
                    storedEndTime = setNewEndTime();
                }
            } else {
                setTimeLeft(timeLeft);
            }
        }, 1000);

        return () => clearInterval(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setNewEndTime = () => {
        const endTime = Date.now() + EXPIRES_IN;
        localStorage.setItem("endTime", endTime.toString());
        return endTime;
    };

    return {
        timeLeft,
        address_used,
        NODE,
        EVENT_SERVER,
        navigate,
        user,
        param,
        handlePayment,
    };
};
