import React from "react";
import { StyledButton } from "./Button.styled";
import { ButtonProps, ButtonVariant } from "./ButoonInteface";

const Button = ({
    variant = ButtonVariant.Primary,
    children,
    ...props
}: ButtonProps) => {
    return (
        <StyledButton variant={variant} {...props}>
            {children}
        </StyledButton>
    );
};

export default Button;
