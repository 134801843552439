import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, Outlet } from "react-router-dom";
import AuthLayout from "./container/AuthLayout";
import MainLayout from "./container/MainLayout";
import { jwtDecode } from "jwt-decode";
import { ThemeProvider } from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logout } from "./common/actions/logoutAction";
import { store } from "./app/store";
import { GlobalStyles } from "./styles/GlobalStyles";
import { useTheme } from "./themes/useTheme";
import { useDispatch } from "react-redux";
import MainLayoutNoFooter from "./container/MainLayoutNoFooter";
import Home from "./pages/home";
import Mining from "./pages/mining";
import Wallet from "./pages/wallet";
import Deposit from "./pages/deposit";
import History from "./pages/history";
import Notification from "./pages/notification";
import Notifications from "./pages/notifications";
import Profile from "./pages/profile";
import Settings from "./pages/settings";
import Withdraw from "./pages/withdraw";
import Login from "./pages/login";
import Register from "./pages/register/Register";
import ForgotPassword from "./pages/forgot-password";
import ChangePassword from "./pages/change-password";
import SetWithdrawalPin from "./pages/set-withdrawal-pin/SetWithdrawalPin";
import LinkedAccount from "./pages/linked-account";
import ScrollToTop from "./ScrollToTop";
import Community from "./pages/community";
import Network from "./pages/network";
import NetworkList from "./pages/network-list";
import Machines from "./pages/machines";
import BuyMachine from "./pages/buy-machine";
import { getToken_, onMessageListener } from "./firebase";
import { Analytics } from "@vercel/analytics/react";

const App = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { theme, themeLoaded } = useTheme();
    const [selectedTheme, setSelectedTheme] = useState(theme);

    useEffect(() => {
        setSelectedTheme(theme);
    }, [theme, themeLoaded]);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token) as any;
            if (decodedToken?.exp * 1000 < Date.now()) {
                store.dispatch(logout({ empty: "" }));
                window.location.pathname = "/login";
            }
        }
    }, [dispatch, navigate]);

    const [, setTokenFound] = useState(false);
    getToken_(setTokenFound);

    onMessageListener()
        .then((payload) => {
            toast.info(payload.notification.body);
        })
        .catch((err) => console.log("failed: ", err));

    return (
        <>
            {themeLoaded && (
                <ThemeProvider theme={selectedTheme}>
                    <Analytics />
                    <ScrollToTop />
                    <GlobalStyles />
                    <Routes>
                        <Route path="/" element={<MainLayout />}>
                            <Route path="/" element={<Home />} />
                            <Route path="/machines" element={<Machines />} />
                            <Route path="/mining" element={<Mining />} />
                            <Route path="/network" element={<Network />} />
                            <Route
                                path="/network-list"
                                element={<NetworkList />}
                            />
                            <Route path="/wallet" element={<Wallet />} />
                        </Route>
                        <Route path="/" element={<MainLayoutNoFooter />}>
                            <Route
                                path="/buy-machine"
                                element={<BuyMachine />}
                            />
                            <Route
                                path="/change-password"
                                element={<ChangePassword />}
                            />
                            <Route
                                path="/set-withdrawal-pin"
                                element={<SetWithdrawalPin />}
                            />
                            <Route path="/deposit/:id" element={<Deposit />} />
                            <Route path="/history" element={<History />} />
                            <Route
                                path="/link-accounts"
                                element={<LinkedAccount />}
                            />
                            <Route
                                path="/notification"
                                element={<Notification />}
                            />
                            <Route
                                path="/notifications"
                                element={<Notifications />}
                            />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/settings" element={<Settings />} />
                            <Route path="/withdraw" element={<Withdraw />} />
                        </Route>
                        <Route
                            path="/"
                            element={
                                <div className="container">
                                    <Outlet />
                                </div>
                            }
                        >
                            <Route path="/community" element={<Community />} />
                        </Route>
                        <Route path="/" element={<AuthLayout />}>
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Register />} />
                            <Route
                                path="/forgot-password"
                                element={<ForgotPassword />}
                            />
                        </Route>
                    </Routes>
                    <ToastContainer />
                </ThemeProvider>
            )}
        </>
    );
};

export default App;
