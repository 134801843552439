import React from "react";
import { useGetOwnedMachines } from "../machines/useMachines";
import PageLoading from "../../components/PageLoading/PageLoading";
import OwnedMachineItem from "../../components/OwnedMachineItem/OwnedMachineItem";
import styled from "styled-components";
import EmptyList from "../../components/EmptyList/EmptyList";

const OwnedMachineListStyle = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    gap: 12px;
`;

const Mining = () => {
    const { ownedMachines, ownedMachinesIsLoading } = useGetOwnedMachines();

    if (ownedMachinesIsLoading) {
        return <PageLoading />;
    }

    if (!ownedMachines.length) {
        return <EmptyList message={"No Machine"} />;
    }

    return (
        <div className="page-content">
            <OwnedMachineListStyle>
                {ownedMachines.map((owned_machine, index) => (
                    <OwnedMachineItem
                        key={index}
                        owned_machine={owned_machine}
                    />
                ))}
            </OwnedMachineListStyle>
        </div>
    );
};

export default Mining;
