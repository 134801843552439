import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { REGISTER_INTERFACE } from "../interface/register";

const endPoint = process.env.REACT_APP_API_URL;

export const register = createAsyncThunk(
    "register",
    async (
        { username, email, password1, password2, referral }: REGISTER_INTERFACE,
        thunkApi
    ) => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const res = await axios.post(
                `${endPoint}/rest-auth/registration/`,
                { username, email, password1, password2, referral },
                config
            );
            return thunkApi.fulfillWithValue(res);
        } catch (error: any) {
            return thunkApi.rejectWithValue(await error.response.data);
        }
    }
);
